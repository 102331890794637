import {Injectable} from "@angular/core";
import {LocalstorageHelper} from "../localstorage/localstorage.helper";
import {UtilsHelper} from "../utils/utils.helper";

@Injectable({
  providedIn: 'root'
})
export class ThemeHelper {

  constructor(
    private localStorage: LocalstorageHelper,
    private utilsHelper: UtilsHelper
  ) {
    // do nothing
  }

  loadTheme(nativeElement: any) {
    const theme = this.localStorage.getLocalStorageTheme()

    if (theme) {
      let colors = new Map<string, string>();

      colors.set('--background-gradient-start-color', theme.backgroundGradientStartColor)
      colors.set('--background-gradient-middle-color', theme.backgroundGradientMiddleColor)
      colors.set('--background-gradient-end-color', theme.backgroundGradientEndColor)
      colors.set('--button-background-gradient-start-color', theme.buttonBackgroundGradientStartColor)
      colors.set('--button-background-gradient-middle-color', theme.buttonBackgroundGradientMiddleColor)
      colors.set('--button-background-gradient-end-color', theme.buttonBackgroundGradientEndColor)
      colors.set('--button-text-gradient-start-color', theme.buttonTextGradientStartColor)
      colors.set('--button-text-gradient-middle-color', theme.buttonTextGradientMiddleColor)
      colors.set('--button-text-gradient-end-color', theme.buttonTextGradientEndColor)
      colors.set('--text-gradient-start-color', theme.textGradientStartColor)
      colors.set('--text-gradient-middle-color', theme.textGradientMiddleColor)
      colors.set('--text-gradient-end-color', theme.textGradientEndColor)

      const root = nativeElement;
      colors.forEach((value, key) => {
        root.style.setProperty(key, value);

        if (value && value.charAt(0) === '#') {
          const rgbValue = this.utilsHelper.convertHexToRgb(value);
          root.style.setProperty(key + '-rgb', rgbValue);
        }
      })

      const metaThemeColor = document.querySelector('meta[name=theme-color]')
      if (metaThemeColor) {
        metaThemeColor.setAttribute('content', theme.backgroundGradientStartColor)
      }
    }
  }
}
