import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {HttpClient} from "@angular/common/http";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";
import {CreateFeedbackDto} from "../../interfaces/dto/create-feedback-dto";
import {Feedback} from "../../interfaces/feedback";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  create(feedback: CreateFeedbackDto): Observable<Feedback> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post<InvocationResult>(url + '/feedbacks', feedback)
      .pipe(
        map((result: InvocationResult) => result.data)
      )
  }
}
