<div class="amount-input">
  <div class="amount-input_dead-zone" (click)="goBack()">

  </div>
  <div class="amount-input_keypad">
    <div class="amount-input_keypad_row">
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('1')" mat-raised-button>
        1
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('2')" mat-raised-button>
        2
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('3')" mat-raised-button>
        3
      </button>
    </div>
    <div class="amount-input_keypad_row">
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('4')" mat-raised-button>
        4
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('5')" mat-raised-button>
        5
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('6')" mat-raised-button>
        6
      </button>
    </div>
    <div class="amount-input_keypad_row">
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('7')" mat-raised-button>
        7
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('8')" mat-raised-button>
        8
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('9')" mat-raised-button>
        9
      </button>
    </div>
    <div class="amount-input_keypad_row">
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('erase')" mat-raised-button>
        <mat-icon>backspace</mat-icon>
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('0')" mat-raised-button>
        0
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('.')" mat-raised-button>
        .
      </button>
      <button class="amount-input_keypad_row_digit" (click)="clickOnKeypad('validate')"
              mat-raised-button>
        <mat-icon>done_outline</mat-icon>
      </button>
    </div>
  </div>
</div>
