import {PaymentStatus} from "./payment-status";

export interface Payment {
  id?: string
  app: string
  demonstration: boolean
  valueInCents: number
  tipId: string
  paymentIntentId?: string
  teamId?: string
  collaboratorIds: string[]
  type: PaymentType
  point: PaymentPoint
  status: PaymentStatus
  cerfaStatus: DocumentStatus
  ticketStatus: DocumentStatus
  comment: string
  invoiceNb?: number
  invoiceMediaId?: string
  invoiceUrl?: string
  ticketMediaId?: string
  ticketUrl?: string
  customerName: string
  customerAddress: string
  customerEmail: string
  finalized: boolean
  refund: boolean
  reference: any
  doneAt: string
}

export enum PaymentType {
  VERIPHONE_PT = "VERIPHONE_PT",
  STRIPE_ONLINE = "STRIPE_ONLINE",
  STRIPE_PT = "STRIPE_PT"
}

export enum PaymentPoint {
  KIOSK,
  WEB_APP
}

export enum DocumentStatus {
  NONE,
  WAITING,
  DONE,
  CANCELLED
}

