import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-amount-keyboard',
  templateUrl: './amount-keyboard.component.html',
  styleUrls: ['./amount-keyboard.component.scss']
})
export class AmountKeyboardComponent {

  @Output() goBackEvent: EventEmitter<any> = new EventEmitter()
  @Output() keyPressed: EventEmitter<string> = new EventEmitter()

  constructor() {
    // do nothing
  }

  clickOnKeypad(digit: string) {
    this.keyPressed.emit(digit)
  }

  goBack() {
    this.goBackEvent.emit()
  }
}
