<div class="admin">
  <div *ngIf="currentSection == AdminSection.MAIN_PAGE" class="admin_main-page_main-page">
    <div class="admin_main-page_clock-in" *ngIf="settings && !settings.enableUniqueRecipient">
      <div class="admin_main-page_clock-in_title" [innerHTML]="'APP.ADMIN.MAIN_PAGE.TITLE' | translate">
      </div>
      <qrcode [qrdata]="token" [width]="250" [allowEmptyString]="true" [errorCorrectionLevel]="'M'"></qrcode>
    </div>
    <div class="admin_main-page_divider"></div>
    <div class="admin_main-page_password">
      <div class="admin_main-page_password_title">
        {{ 'APP.ADMIN.MAIN_PAGE.ENTER_PASSWORD' | translate }}
      </div>
      <input type="text" [placeholder]="password" readonly>
      <div class="admin_main-page_password_keypad">
        <div class="admin_main-page_password_keypad_row">
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('1')" mat-raised-button>
            1
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('2')" mat-raised-button>
            2
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('3')" mat-raised-button>
            3
          </button>
        </div>
        <div class="admin_main-page_password_keypad_row">
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('4')" mat-raised-button>
            4
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('5')" mat-raised-button>
            5
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('6')" mat-raised-button>
            6
          </button>
        </div>
        <div class="admin_main-page_password_keypad_row">
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('7')" mat-raised-button>
            7
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('8')" mat-raised-button>
            8
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('9')" mat-raised-button>
            9
          </button>
        </div>
        <div class="admin_main-page_password_keypad_row">
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('erase')" mat-raised-button>
            <mat-icon>backspace</mat-icon>
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('0')" mat-raised-button>
            0
          </button>
          <button class="admin_main-page_password_keypad_row_digit" (click)="clickOnKeypad('validate')"
                  mat-raised-button>
            <mat-icon>done_outline</mat-icon>
          </button>
        </div>
        <div class="admin_main-page_password_keypad_row">
          <button class="admin_main-page_password_keypad_row_back-btn" (click)="doNavigateBackward()"
                  mat-raised-button>
            <mat-icon>arrow_back</mat-icon>
            <span>{{ 'APP.ADMIN.BUTTONS.BACK' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentSection != AdminSection.MAIN_PAGE" class="admin_locked-page">

    <div *ngIf="currentSection == AdminSection.ADMIN_TILES" class="admin_locked-page_tiles">
      <div class="tile" (click)="adminSectionClicked(AdminSection.ADMIN_INFORMATIONS)">
        <mat-icon class="tile_icon">feed</mat-icon>
        <span class="tile_title">{{ 'APP.ADMIN.ADMIN_TILES.INFORMATIONS_TILE' | translate }}</span>
      </div>
      <div class="tile" (click)="clearLocalStorage()">
        <mat-icon class="tile_icon">restart_alt</mat-icon>
        <span class="tile_title">Reset LocalStorage</span>
      </div>
    </div>

    <ng-container *ngIf="currentSection == AdminSection.ADMIN_INFORMATIONS">
      <div *ngIf="device" class="admin_locked-page_info">

        <div class="information_section">
          <span class="information_section_title">
            {{ 'APP.ADMIN.ADMIN_INFORMATIONS.DEVICE_INFORMATION.TITLE' | translate }}
          </span>
          <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.DEVICE_INFORMATION.ID' | translate }} : {{ device.id }}</span>
          <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.DEVICE_INFORMATION.NAME' | translate }} : {{ device.name }}</span>
        </div>

        <div class="information_section">
          <span class="information_section_title">
            {{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.TITLE' | translate }}
          </span>

          <ng-container *ngIf="settings" [ngSwitch]="paymentType">
            <ng-container
              *ngSwitchCase="PaymentType.VERIPHONE_PT">{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.VERIFONE_PAYMENT_TYPE' | translate }}
            </ng-container>
            <ng-container
              *ngSwitchCase="PaymentType.STRIPE_PT">{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.STRIPE_PT_PAYMENT_TYPE' | translate }}
            </ng-container>
            <ng-container
              *ngSwitchDefault>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.UNKNOWN_PAYMENT_TYPE' | translate }}
            </ng-container>

            <div *ngIf="paymentType == PaymentType.STRIPE_PT" class="information_section">
              <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.STRIPE_TERMINAL.LABEL' | translate }}
                : {{ stripeReader.label }}</span>
              <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.STRIPE_TERMINAL.IP_ADDRESS' | translate }}
                : {{ stripeReader.ipAddress }}</span>
              <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.PAYMENT_INFORMATION.STRIPE_TERMINAL.SERIAL_NUMBER' | translate }}
                : {{ stripeReader.serialNumber }}</span>
            </div>
          </ng-container>

        </div>

        <div class="information_section">
          <span>{{ 'APP.ADMIN.ADMIN_INFORMATIONS.APP_VERSION' | translate }} : {{ appVersion }}</span>
        </div>
      </div>
    </ng-container>


    <button class="admin_locked-page_back-btn" (click)="doNavigateBackward()"
            mat-raised-button>
      <mat-icon>arrow_back</mat-icon>
      <span>{{ 'APP.ADMIN.BUTTONS.BACK' | translate }}</span>
    </button>
  </div>
</div>
