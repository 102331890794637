import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'classByChar'})
export class ClassByCharPipe implements PipeTransform {

  constructor() {
    // do nothing
  }

  transform(value: string, className: string): string {
    var htmlParts = []
    for (var i = 0; i < value.length; i++) {
      htmlParts.push(`<span class='${className}'>`);
      var char = value[i]
      htmlParts.push((char === "") ? "&nbsp;" : char);
      htmlParts.push("</span>");
    }
    return htmlParts.join("")
  }
}
