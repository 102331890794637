import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './pages/home/home.component';
import {CollaboratorComponent} from "./widgets/collaborators/collaborator/collaborator.component";
import {CommonModule, DecimalPipe} from "@angular/common";
import {TranslationModule} from "./translation/translation.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthInterceptor} from "./shared/interceptors/auth-interceptor.service";
import {StripePaymentModalComponent} from './widgets/stripe-payment-modal/stripe-payment-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {environment} from "../environments/environment";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxStripeModule} from "ngx-stripe";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_KEYBOARD_LAYOUTS, MatKeyboardModule} from "angular-onscreen-material-keyboard";
import {CustomKeyboardLayout} from "./shared/services/custom-keyboard-layout";
import {MatIconModule} from "@angular/material/icon";
import {CustomerDetailsComponent} from './widgets/customer-details/customer-details.component';
import {KioskApiInterceptor} from "./shared/interceptors/kiosk-api.interceptor";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {PairingComponent} from "./pages/pairing/pairing.component";
import {MatSelectModule} from "@angular/material/select";
import {ErrorComponent} from './pages/error/error.component';
import {NgxSpinnerModule} from "ngx-spinner";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AdminComponent} from './pages/admin/admin.component';
import {TeamComponent} from './widgets/teams/team/team.component';
import {QRCodeModule} from "angularx-qrcode";
import {ValidatorErrorsComponent} from "./widgets/validator-errors/validator-errors.component";
import {ClassByCharPipe} from "./shared/pipes/class-by-char/class-by-char.pipe";
import {AmountKeyboardComponent} from './widgets/amount-keyboard/amount-keyboard.component';
import {ReasonComponent} from './widgets/reason/reason.component';
import {DynamicTranslationPipe} from "./shared/pipes/dynamic-translation/dynamic-translation.pipe";
import {LanguageSelectorComponent} from './widgets/language-selector/language-selector.component';
import {PreselectedAmountsComponent} from './widgets/preselected-amounts/preselected-amounts.component';
import { HumetipsSelectorComponent } from './widgets/humetips-selector/humetips-selector.component';
import { AppSelectionComponent } from './pages/app-selection/app-selection.component';
import {FeedbackComponent} from "./pages/feedback/feedback.component";

const devProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KioskApiInterceptor,
    multi: true
  },
]

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CollaboratorComponent,
    StripePaymentModalComponent,
    CustomerDetailsComponent,
    PairingComponent,
    ErrorComponent,
    FeedbackComponent,
    AdminComponent,
    TeamComponent,
    ValidatorErrorsComponent,
    ClassByCharPipe,
    AmountKeyboardComponent,
    DynamicTranslationPipe,
    ReasonComponent,
    LanguageSelectorComponent,
    PreselectedAmountsComponent,
    HumetipsSelectorComponent,
    AppSelectionComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    TranslationModule,
    AppRoutingModule,
    MatDialogModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxStripeModule.forRoot(),
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatKeyboardModule,
    HammerModule,
    NgxSpinnerModule,
    QRCodeModule,
    MatCheckboxModule
  ],
  providers: [
    DynamicTranslationPipe,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: CustomKeyboardLayout.getCustomLayout()
    },
    !environment.production ? devProviders : []
  ],
  entryComponents: [
    StripePaymentModalComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
