import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Amount} from "../../interfaces/amount";

@Component({
  selector: 'app-preselected-amounts',
  templateUrl: './preselected-amounts.component.html',
  styleUrls: ['./preselected-amounts.component.scss']
})
export class PreselectedAmountsComponent {

  @Output() onAmountClicked: EventEmitter<number> = new EventEmitter<number>()
  @Input() amounts: Amount[] = []

  constructor() {
    // do nothing
  }

  setPreselectedTipValue(amount: number) {
    this.onAmountClicked.emit(amount)
  }
}
