<div class="error" (click)="errorScreenClicked()">
  <div class="error_value" [ngSwitch]="errorReason">
    <span *ngSwitchCase="ErrorType.STRIPE_PT_NOT_CONNECTED"
          [innerHTML]="'APP.ERRORS.STRIPE_PT_NOT_CONNECTED' | translate">
    </span>
    <span *ngSwitchCase="ErrorType.STRIPE_ONLINE_PAYMENT_TYPE_NOT_ENABLED"
          [innerHTML]="'APP.ERRORS.STRIPE_ONLINE_PAYMENT_TYPE_NOT_ENABLED' | translate">
    </span>
    <span *ngSwitchCase="ErrorType.ASK_FOR_SIMULATION_UNAUTHORIZED"
          [innerHTML]="'APP.ERRORS.ASK_FOR_SIMULATION_UNAUTHORIZED' | translate">

    </span>
    <span *ngSwitchCase="ErrorType.VERIPHONE_NOT_STARTED"
          [innerHTML]="'APP.ERRORS.VERIPHONE_NOT_STARTED' | translate">

    </span>
    <span *ngSwitchDefault
          [innerHTML]="'APP.ERRORS.DEFAULT' | translate">
    </span>
  </div>
  <br/><br/><br/>
  <mat-spinner *ngIf="errorReason == ErrorType.STRIPE_PT_NOT_CONNECTED"
               [diameter]="200"></mat-spinner>
</div>
