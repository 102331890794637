export enum MediaType {
  Art = "Art",
  Image = "Image",
  PDF = "PDF",
  Template = "Template",
  Video = "Video",
  Unknown = "Unknown"
}

export enum MediaStatus {
  Processing = "Processing",
  Success = "Success",
  Failed = "Failed"
}

export interface Media {
  id: string
  customerId: string
  name: string
  public: boolean
  type: MediaType
  status: MediaStatus
  url: string
  thumbnailUrl: string
}


export enum QueryScope {
  CUSTOMER = "CUSTOMER",
  ANCESTORS = "ANCESTORS",
  CHILDREN = "CHILDREN",
  CUSTOMER_AND_ANCESTORS = "CUSTOMER_AND_ANCESTORS",
  CUSTOMER_AND_PUBLIC_ANCESTORS = "CUSTOMER_AND_PUBLIC_ANCESTORS",
  CUSTOMER_AND_CHILDREN = "CUSTOMER_AND_CHILDREN"
}
