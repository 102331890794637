import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Observable} from "rxjs";
import {ConnectionToken} from "@stripe/terminal-js";
import {PaymentIntent} from "../../interfaces/payment-intent";
import {CreatePaymentIntentDto} from "../../interfaces/dto/create-payment-intent-dto";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class StripeApiService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  getStripeAuthToken(): Observable<ConnectionToken> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post(url + '/stripe/getConnectionToken', {})
      .pipe(map((result: InvocationResult) => {
          return result.data.secret
        })
      )
  }

  getPaymentIntent(id: string): Observable<PaymentIntent> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.get(`${url}/stripe/payment/${id}`)
      .pipe(map((res: InvocationResult) => {
          return res.data
        })
      )
  }

  createPaymentIntent(createPaymentIntentDto: CreatePaymentIntentDto): Observable<PaymentIntent> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post(`${url}/paymentIntents`, createPaymentIntentDto)
      .pipe(map((res: InvocationResult) => {
          console.log('create : ', res)
          return res.data
        })
      )
  }

  confirmPayment(paymentIntentId: string, paymentMethodId: string): Observable<any> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post(`${url}/stripe/confirmPayment`, {
      paymentIntentId: paymentIntentId,
      paymentMethodId: paymentMethodId
    }).pipe(
      map((res: any) => {
        console.log('confirm : ', res)
        return res.data
      })
    )
  }

  capturePayment(paymentIntentId: string): Observable<any> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post(`${url}/stripe/capturePayment`, {
      paymentIntentId: paymentIntentId
    }).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }
}
