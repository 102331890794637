<div class="feedback" [ngClass]="{'feedback--no-background-image' : !backgroundMediaUrl}" [ngStyle]="{'background-image' : (backgroundMediaUrl ? backgroundMediaUrl : null)}">
  <div class="feedback_header">
    <div class="feedback_header_logo">
      <img *ngIf="logo" class="feedback_header_logo_source" [src]="logo.url"/>
      <img *ngIf="!logo" class="feedback_header_logo_source" src="assets/images/icons/icon-humetips.svg"/>
    </div>
    <div class="feedback_header_text" *ngIf="feedback == -1">
      <div *ngIf="showEvaluationLabel"
           [innerHtml]="theme.evaluationLabel | dynamicTranslation: this.selectedLanguage.code | classByChar: 'text-gradient-letter'">
      </div>
      <span *ngIf="!showEvaluationLabel">{{ 'APP.FEEDBACK.TITLE_AFTER' | translate }}</span>
    </div>
  </div>
  <div class="feedback_container" *ngIf="feedback == -1">
    <div class="feedback_container_review" (click)="giveFeedback(0)">
      <img src="assets/images/feedback/notHappy.svg"/>
      <span>{{ 'APP.FEEDBACK.REVIEW_0.TITLE' | translate }}</span>
    </div>
    <div class="feedback_container_review" (click)="giveFeedback(2)">
      <img src="assets/images/feedback/neutral.svg"/>
      <span>{{ 'APP.FEEDBACK.REVIEW_2.TITLE' | translate }}</span>
    </div>
    <div class="feedback_container_review" (click)="giveFeedback(4)">
      <img src="assets/images/feedback/happy.svg"/>
      <span>{{ 'APP.FEEDBACK.REVIEW_4.TITLE' | translate }}</span>
    </div>
  </div>
  <div class="feedback_footer">
    <div class="feedback_footer_value" *ngIf="feedback != -1"
         [ngStyle]="{'animation-name': feedback != -1 ? 'fadeIn' : 'none'}">
      <img [src]="'assets/images/feedback/' + feedback + '.png'"/>
      <br/><br/>
      <span>{{ answer }}</span>
      <br/><br/>
      <span *ngIf="feedback >= MIN_FEEDBACK_NOTATION_TO_TIP">
        {{ 'APP.FEEDBACK.LEAVE_A_TIP' | translate }}
      </span>
    </div>
  </div>
</div>
