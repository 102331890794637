import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
} from '@angular/core';
import {Theme} from "../../interfaces/theme";
import {Media} from "../../shared/interfaces/media";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {FeedbackService} from "../../services/feedback/feedback.service";
import {Language} from "../../interfaces/language";
import {MediaService} from "../../services/medias/media.service";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, AfterViewInit {

  readonly MIN_FEEDBACK_NOTATION_TO_TIP: number = 3
  readonly TIME_AFTER_REVIEW_IN_MS: number = 2000

  @Output() showTipScreen: EventEmitter<'backward' | 'forward'> = new EventEmitter<'backward' | 'forward'>()

  @Input() theme: Theme = null
  @Input() logo: Media = null
  @Input() selectedLanguage: Language = {code: "fr", name: "French", nativeName: "Français"}

  subscriptions: Subscription = new Subscription()

  backgroundMediaUrl: string | null = null
  showEvaluationLabel = false
  feedback: number = -1
  answer: string = ""

  reviews_answers: string[][] = Array(5)
    .fill(0)
    .map((_, i) =>
      Array(5)
        .fill(0)
        .map((_, j) =>
          this.translateService.instant(`APP.FEEDBACK.REVIEW_${i}.ANSWERS.${j + 1}`))
    )

  constructor(
    private feedbackService: FeedbackService,
    private mediaService: MediaService,
    private translateService: TranslateService,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.showEvaluationLabel = this.theme?.evaluationLabel.some(it => it.value && (it.code === this.selectedLanguage.code))
  }

  ngAfterViewInit(): void {
    this.retrieveBackground()
  }

  giveFeedback(feedback: number): void {
    this.feedback = feedback

    let random = this.getRandomInt(5)
    this.answer = this.reviews_answers[feedback][random]

    this.subscriptions.add(this.feedbackService.create({
      score: feedback
    }).subscribe(_ => {
      setTimeout(() => {
        if (feedback >= this.MIN_FEEDBACK_NOTATION_TO_TIP) {
          this.showTipScreen.emit("forward");
        } else {
          this.feedback = -1
          this.answer = ""
          this.showTipScreen.emit("backward");
        }
      }, this.TIME_AFTER_REVIEW_IN_MS);
    }))
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * max)
  }

  retrieveBackground() {
    if (this.theme?.backgroundMediaId) {
      this.subscriptions.add(
        this.mediaService.getMedia(this.theme.backgroundMediaId).subscribe((media) => {
          this.backgroundMediaUrl = `url("${media?.url}")`
        })
      )
    }
  }
}
