export class AndroidMessage {
  type?: AndroidMessageType
  source?: AndroidMessageSource
  data?: AndroidMessageData
}

export class AndroidMessageData {
  name?: string
  stripeInfos?: StripeInformations
  paymentResult?: PaymentResult
  readerConnected?: boolean
  newTokenNeeded?: boolean
  customerUrl?: string
}

export enum AndroidMessageSource {
  WEB_APP,
  ANDROID_KIOSK_APP
}

export enum AndroidMessageType {
  APPLICATION_READY = "APPLICATION_READY",
  RESET_PAIRING = "RESET_PAIRING",
  STRIPE_INFORMATIONS = "STRIPE_INFORMATIONS",
  PAYMENT_RESULT = "PAYMENT_RESULT",
  READER_CONNECTION_STATUS = "READER_CONNECTION_STATUS",
  CLEAR_CACHE = "CLEAR_CACHE",
  RESET_APP = "RESET_APP"
}

export class ReceivePaymentRequestAndroidMessage {
  clientSecret: string
}

export class StripeInformations {
  token?: string
  locationId?: string
}

export class PaymentResult {
  success?: boolean
  stripePaymentIntent?: any
}
