import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription, zip} from "rxjs";
import {CollaboratorService} from "../../services/collaborators/collaborator.service";
import {Device} from "../../interfaces/device";
import {Settings} from "../../interfaces/settings";
import {DeviceService} from "../../services/devices/device.service";
import {LocalstorageHelper} from "../../shared/helpers/localstorage/localstorage.helper";
import packageJson from '../../../../package.json';
import {StripeReaderService} from "../../services/stripe-reader/stripe-reader.service";
import {StripeReader} from "../../interfaces/stripe-reader";
import {PaymentType} from 'src/app/interfaces/payment';

export enum AdminSection {
  MAIN_PAGE,
  ADMIN_TILES,
  ADMIN_INFORMATIONS
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  @Output() goBackEvent: EventEmitter<any> = new EventEmitter()
  @Output() resetLocalStorageEvent: EventEmitter<any> = new EventEmitter()
  @Input() settings: Settings

  PaymentType = PaymentType
  AdminSection = AdminSection

  subscriptions: Subscription = new Subscription()
  password: string = ""
  token: string = ""
  paymentType: PaymentType
  stripeReader: StripeReader
  device: Device
  appVersion = packageJson.version;
  currentSection: AdminSection = AdminSection.MAIN_PAGE

  constructor(
    private collaboratorService: CollaboratorService,
    private deviceService: DeviceService,
    private stripeReaderService: StripeReaderService,
    private localstorageHelper: LocalstorageHelper
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscriptions.add(this.collaboratorService.getClockInToken().subscribe(token => {
      this.token = token
    }))
  }

  clickOnKeypad(digit: string) {
    switch (digit) {
      case "validate":
        this.checkIfPasswordCorrect()
        break;
      case "erase":
        this.password = this.password.substring(0, this.password.length - 1)
        break;
      default:
        this.password += digit
        break;
    }
  }

  checkIfPasswordCorrect() {
    if (this.password == this.settings.administratorPassword) {
      this.currentSection = AdminSection.ADMIN_TILES
    }
  }

  adminSectionClicked(section: AdminSection) {
    this.currentSection = section

    switch (section) {
      case AdminSection.ADMIN_INFORMATIONS:
        this.retrieveAndDisplayInformation()
        break
    }
  }

  doNavigateBackward() {
    switch (this.currentSection) {
      case AdminSection.MAIN_PAGE:
      case AdminSection.ADMIN_TILES:
        this.goBackEvent.emit()
        break
      case AdminSection.ADMIN_INFORMATIONS:
        this.currentSection = AdminSection.ADMIN_TILES
        break
    }
  }

  clearLocalStorage() {
    this.resetLocalStorageEvent.emit()
  }

  retrieveAndDisplayInformation() {
    this.paymentType = this.settings.enabledPaymentTypes.includes(PaymentType.STRIPE_PT)
      ? PaymentType.STRIPE_PT
      : this.settings.enabledPaymentTypes.includes(PaymentType.VERIPHONE_PT)
        ? PaymentType.VERIPHONE_PT
        : null

    let deviceId = this.localstorageHelper.getLocalStorageDeviceId();
    this.subscriptions.add(
      zip(
        this.deviceService.getDevice(deviceId),
        this.stripeReaderService.getStripeReaderByDeviceId(deviceId)
      ).subscribe(([device, stripeReader]) => {
        this.device = device;
        this.stripeReader = stripeReader;
      })
    );
  }
}
