import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {StripeReader} from "../../interfaces/stripe-reader";
import {HttpClient, HttpParams} from "@angular/common/http";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {catchError, map} from "rxjs/operators";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StripeReaderService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  getStripeReaders(updateAll: boolean): Observable<StripeReader[]> {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    let queryParams = new HttpParams()
      .set('update', updateAll)
      .set('onlyNotPaired', true)
      .set('testingMode', !environment.production)

    return this.httpClient.get(`${url}/stripe-readers`, {params: queryParams}).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  updateStripeReaders(reader: StripeReader) {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    return this.httpClient.put(`${url}/stripe-readers/${reader.id}`, reader).pipe(
      map((res: InvocationResult) => {
        return res.data
      })
    )
  }

  getStripeReaderByDeviceId(deviceId: string): Observable<StripeReader> {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    return this.httpClient.get(`${url}/stripe-readers/device/${deviceId}`).pipe(
      map((res: InvocationResult) => {
        return res.data
      }),
      catchError(_ => of(null))
    )
  }
}
