<div class="preselected-tips" (click)="setPreselectedTipValue(null)">
  <div class="preselected-tips_list">
    <div class="preselected-tips_list_amount" *ngFor="let amount of amounts"
         (click)="setPreselectedTipValue(amount.value)">
      <div class="preselected-tips_list_amount_text"
           [innerHtml]="(amount.value + '€') | classByChar: 'button-text-gradient-letter'">
      </div>
    </div>
  </div>
</div>
