<div *ngIf="team" class="team" [class.team--selected]="selected"
     (click)="selectTeam()">
  <div class="team_icon">
    <img *ngIf="iconMediaId" class="team_icon_source"
         [class.team_icon_source--selected]="selected"
         [src]="iconMediaId"/>
    <img *ngIf="!iconMediaId" class="team_icon_source"
         [class.team_icon_source--selected]="selected"
         src="assets/images/teams/no_icon.png"/>
  </div>
  <div class="team_name" [class.team_name--selected]="selected"
       [innerHtml]="team.name | classByChar: 'button-text-gradient-letter'">
  </div>
</div>
