<div class="pairing">
  <div class="pairing_header">
    <div class="pairing_header_icon">
      <img class="pairing_header_icon_src" src="assets/images/icons/icon-humetips.svg"
           (click)="canChangeInstance = true"/>
    </div>
    <div class="pairing_header_logo">
      <img class="pairing_header_logo_title" src="assets/images/icons/logo-humetips.svg"/>
      <div class="pairing_header_logo_description">
        Appairage de votre kiosk
      </div>
    </div>
  </div>
  <div class="pairing_body">
    <div class="pairing_body_instance">
      <mat-form-field class="pairing_body_instance_select" appearance="fill">
        <mat-label>Sélectionnez votre instance</mat-label>
        <mat-select [(value)]="instance" [disabled]="!canChangeInstance" (valueChange)="instanceChanged()">
          <mat-option value="production">HUMECLOUD</mat-option>
          <mat-option value="development">HUMECLOUD DEV</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="pairing_body_texts">
      <div class="pairing_body_texts_infos">
        Entrez ce code sur la page de votre équipement pour l'appairage
      </div>
      <div class="pairing_body_texts_code">
        {{ pairingCode }}
      </div>
      <div class="pairing_body_texts_timer">
        Il reste {{ minutes }} minute(s) et {{ seconds }} secondes
      </div>
    </div>
  </div>
</div>
