export interface Collaborator {
  id?: string
  firstName: string
  lastName: string
  email: string
  profilePictureMediaIdsScoped?: CollaboratorProfilePictures[]
  status: CollaboratorStatus
  kycStatus: StripeKycStatus
  stripeConnectId?: string
  userId?: string
  customerIds?: string[]
}

export interface CollaboratorProfilePictures {
  customerId: string,
  pictureMediaId: string
}

export enum CollaboratorStatus {
  DISABLED = "DISABLED",
  INVITATION_SENT = "INVITATION_SENT",
  NEED_APPROVAL = "NEED_APPROVAL",
  READY = "READY",
}

export enum StripeKycStatus {
  UNKNOWN = "UNKNOWN",
  UNCOMPLETED = "UNCOMPLETED",
  COMPLETED = "COMPLETED"
}
