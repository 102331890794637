import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Collaborator, CollaboratorProfilePictures} from "../../../interfaces/collaborator";
import {Subscription} from "rxjs";
import {MediaService} from "../../../services/medias/media.service";
import {LocalstorageHelper} from "../../../shared/helpers/localstorage/localstorage.helper";

@Component({
  selector: 'app-collaborator',
  templateUrl: './collaborator.component.html',
  styleUrls: ['./collaborator.component.scss']
})
export class CollaboratorComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  @Output() clicked: EventEmitter<Collaborator> = new EventEmitter()
  @Input() collaborator: Collaborator
  @Input() selected: boolean

  profilePictureUrl: string = null

  constructor(
    private mediaService: MediaService,
    private localStorageHelper: LocalstorageHelper,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    if (this.collaborator && this.collaborator.profilePictureMediaIdsScoped) {
      this.retrieveMedia(this.collaborator.profilePictureMediaIdsScoped)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  selectCollaborator() {
    this.clicked.emit(this.collaborator)
  }

  retrieveMedia(profilePictureMediaIdsScoped: CollaboratorProfilePictures[]): void {
    let cid = this.localStorageHelper.getLocalStorageCid()
    let pictureProfileMedia = profilePictureMediaIdsScoped.find(x => x.customerId == cid)

    if (pictureProfileMedia) {
      this.subscriptions.add(
        this.mediaService.getMedia(pictureProfileMedia.pictureMediaId).subscribe(media => {
          this.profilePictureUrl = media.thumbnailUrl;
        })
      );
    }
  }
}
