import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {LocalstorageHelper} from "../localstorage/localstorage.helper";

@Injectable({
  providedIn: 'root'
})
export class ApplicationHelper {

  constructor(
    private localStorageHelper: LocalstorageHelper
  ) {
  }

  getApiUrlByType(type: string) {
    const instance = this.localStorageHelper.getLocalInstance()
    switch (type) {
      case "URL":
        return instance == "production" ?
          environment.htiApi.prod.url :
          environment.htiApi.dev.url
      case "HTI":
        return instance == "production" ?
          environment.htiApi.prod.api :
          environment.htiApi.dev.api
      case "SCREENLAB":
        return instance == "production" ?
          environment.screenlabApiBaseUrlProd :
          environment.screenlabApiBaseUrlDev
      case "CMN_APP":
        return instance == "production" ?
          environment.cmnAppApiBaseUrlProd :
          environment.cmnAppApiBaseUrlDev
      case "MEDIAS":
        return instance == "production" ?
          environment.screenlabApiBaseUrlProd :
          environment.screenlabApiBaseUrlDev
      case "KEYCLOACK":
        return instance == "production" ?
          environment.keycloak.prod.url :
          environment.keycloak.dev.url
      default:
        return environment.htiApi.prod.api
    }
  }
}
