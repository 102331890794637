import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Amount} from "../../interfaces/amount";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class AmountService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  getAmounts(): Observable<Amount[]> {
    const url = this.appHelper.getApiUrlByType('HTI')

    const queryParams = new HttpParams()
      .set('perPage', '100')
      .set('page', '0');

    return this.httpClient.get(url + '/amounts', {params: queryParams})
      .pipe(map((res: InvocationResult) => {
          return res.data
        })
      );
  }
}
