<div *ngIf="collaborator" class="collaborator" [class.collaborator--selected]="selected"
     (click)="selectCollaborator()">
  <div class="collaborator_picture">
    <img *ngIf="profilePictureUrl" class="collaborator_picture_source"
         [class.collaborator_picture_source--selected]="selected"
         [src]="profilePictureUrl"/>
    <img *ngIf="!profilePictureUrl" class="collaborator_picture_source"
         [class.collaborator_picture_source--selected]="selected"
         src="assets/images/collaborators/no_picture.png"/>
  </div>
  <div class="collaborator_name" [class.collaborator_name--selected]="selected"
       [innerHtml]="collaborator.firstName | classByChar: 'button-text-gradient-letter'">
  </div>
</div>
