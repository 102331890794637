import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import * as crypto from "crypto-js";
import {Device, PeripheralConfig} from "../../interfaces/device";
import {LocalstorageHelper} from "../../shared/helpers/localstorage/localstorage.helper";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";
import {PatchDeviceDto} from "../../interfaces/dto/patch-device-dto";

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private httpClient: HttpClient,
    private localStorageHelper: LocalstorageHelper,
    private appHelper: ApplicationHelper
  ) {
    // do nothing
  }

  pairing(instance: string, id: string): Observable<any> {
    this.localStorageHelper.setLocalInstance(instance)
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    const queryHeader = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')

    return this.httpClient.post(`${url}/public/devices/pairing/${id}`,
      {data: {}}, {headers: queryHeader})
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  status(deviceId: string): Observable<Device> {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    const queryHeader = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')

    const deviceIdSha = crypto.SHA1(deviceId).toString();
    const devicePassword = crypto.SHA1(deviceIdSha.substring(3, deviceIdSha.length - 3)).toString();

    let body = new HttpParams();
    body = body.set('user', deviceId);
    body = body.set('password', devicePassword);

    return this.httpClient.post(`${url}/public/devices/status`, body, {headers: queryHeader})
      .pipe(
        map((res: any) => {
          return res
        })
      )
  }

  patchDevicePeripheralConfig(deviceId: string, peripheralConfig: PeripheralConfig): Observable<Device> {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')
    const patchDeviceDto: PatchDeviceDto = {
      update: {
        peripheralConfig: {
          paymentTerminalType: peripheralConfig.paymentTerminalType,
          stripeReaderId: peripheralConfig.stripeReaderId,
          verifoneReaderId: peripheralConfig.verifoneReaderId
        }
      }
    }

    return this.httpClient.patch(`${url}/devices/${deviceId}`, patchDeviceDto)
      .pipe(
        map((res: any) => {
          return res.data
        })
      )
  }

  getDevice(deviceId: string): Observable<Device> {
    const url = this.appHelper.getApiUrlByType('SCREENLAB')

    return this.httpClient.get(`${url}/devices/${deviceId}`)
      .pipe(
        map((res: any) => {
          return res.data
        })
      )
  }
}
