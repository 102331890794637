import {IKeyboardLayouts, KeyboardClassKey, keyboardLayouts} from "angular-onscreen-material-keyboard";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class CustomKeyboardLayout {

  static getCustomLayout(): IKeyboardLayouts {
    return this.customLayout
  }

  private static customLayout: IKeyboardLayouts = {
    ...keyboardLayouts,
    'keyboard_complete_fr': {
      'name': 'Custom Complete Keyboard - FR',
      'keys': [
        [
          ['@', '@'],
          ['&', '1'],
          ['\u00e9', '2', '~'],
          ['"', '3', '#'],
          ['\'', '4', '{'],
          ['(', '5', '['],
          ['-', '6', '|'],
          ['\u00e8', '7', '`'],
          ['_', '8', '\\'],
          ['\u00e7', '9', '^'],
          ['\u00e0', '0', '@'],
          [')', '\u00b0', ']'],
          ['=', '+', '}'],
          [KeyboardClassKey.Bksp, KeyboardClassKey.Bksp, KeyboardClassKey.Bksp, KeyboardClassKey.Bksp]
        ],
        [
          [KeyboardClassKey.Tab, KeyboardClassKey.Tab, KeyboardClassKey.Tab, KeyboardClassKey.Tab],
          ['a', 'A'],
          ['z', 'Z'],
          ['e', 'E', '\u20ac'],
          ['r', 'R'],
          ['t', 'T'],
          ['y', 'Y'],
          ['u', 'U'],
          ['i', 'I'],
          ['o', 'O'],
          ['p', 'P'],
          ['^', '\u00a8'],
          ['$', '\u00a3', '\u00a4'],
          ['*', '\u03bc']
        ],
        [
          [KeyboardClassKey.Caps, KeyboardClassKey.Caps, KeyboardClassKey.Caps, KeyboardClassKey.Caps],
          ['q', 'Q'],
          ['s', 'S'],
          ['d', 'D'],
          ['f', 'F'],
          ['g', 'G'],
          ['h', 'H'],
          ['j', 'J'],
          ['k', 'K'],
          ['l', 'L'],
          ['m', 'M'],
          ['\u00f9', '%'],
          [KeyboardClassKey.Enter, KeyboardClassKey.Enter, KeyboardClassKey.Enter, KeyboardClassKey.Enter]
        ],
        [
          [KeyboardClassKey.Shift, KeyboardClassKey.Shift, KeyboardClassKey.Shift, KeyboardClassKey.Shift],
          ['<', '>'],
          ['w', 'W'],
          ['x', 'X'],
          ['c', 'C'],
          ['v', 'V'],
          ['b', 'B'],
          ['n', 'N'],
          [',', '?'],
          [';', '.'],
          [':', '/'],
          ['!', '\u00a7'],
          [KeyboardClassKey.Shift, KeyboardClassKey.Shift, KeyboardClassKey.Shift, KeyboardClassKey.Shift]
        ],
        [
          [KeyboardClassKey.Space, KeyboardClassKey.Space, KeyboardClassKey.Space, KeyboardClassKey.Space],
        ]
      ],
      'lang': ['fr']
    },
    'keyboard_simple_fr': {
      'name': 'Custom Simple Keyboard - FR',
      'keys': [
        [
          ['1', '1'],
          ['2', '2'],
          ['3', '3'],
          ['4', '4'],
          ['5', '5'],
          ['6', '6'],
          ['7', '7'],
          ['8', '8'],
          ['9', '9'],
          ['0', '0'],
          [KeyboardClassKey.Bksp, KeyboardClassKey.Bksp]
        ],
        [
          ['a', 'A'],
          ['z', 'Z'],
          ['e', 'E'],
          ['r', 'R'],
          ['t', 'T'],
          ['y', 'Y'],
          ['u', 'U'],
          ['i', 'I'],
          ['o', 'O'],
          ['p', 'P']
        ],
        [
          [KeyboardClassKey.Caps, KeyboardClassKey.Caps],
          ['q', 'Q'],
          ['s', 'S'],
          ['d', 'D'],
          ['f', 'F'],
          ['g', 'G'],
          ['h', 'H'],
          ['j', 'J'],
          ['k', 'K'],
          ['l', 'L'],
          ['m', 'M']
        ],
        [
          [KeyboardClassKey.Shift, KeyboardClassKey.Shift],
          ['w', 'W'],
          ['x', 'X'],
          ['c', 'C'],
          ['v', 'V'],
          ['b', 'B'],
          ['n', 'N'],
          ['.', '.'],
          ['-', '-'],
          [KeyboardClassKey.Enter, KeyboardClassKey.Enter]
        ],
        [
          [KeyboardClassKey.Space, KeyboardClassKey.Space]
        ]
      ],
      'lang': ['fr']
    },
    'keyboard_email_fr': {
      'name': 'Custom Email Keyboard - FR',
      'keys': [
        [
          ['1'],
          ['2'],
          ['3'],
          ['4'],
          ['5'],
          ['6'],
          ['7'],
          ['8'],
          ['9'],
          ['0'],
          [KeyboardClassKey.Bksp]
        ],
        [
          ['a'],
          ['z'],
          ['e'],
          ['r'],
          ['t'],
          ['y'],
          ['u'],
          ['i'],
          ['o'],
          ['p'],
        ],
        [
          ['q'],
          ['s'],
          ['d'],
          ['f'],
          ['g'],
          ['h'],
          ['j'],
          ['k'],
          ['l'],
          ['m'],
          [KeyboardClassKey.Enter]
        ],
        [
          ['w'],
          ['x'],
          ['c'],
          ['v'],
          ['b'],
          ['n'],
          ['.'],
          ['-'],
          ['_'],
          ['@']
        ],
        [
          [KeyboardClassKey.Space]
        ]
      ],
      'lang': ['fr']
    },
    'keyboard_amount': {
      'name': 'Custom Amount Keyboard - FR',
      'keys': [
        [
          ['1'],
          ['2'],
          ['3'],
        ],
        [
          ['4'],
          ['5'],
          ['6'],
        ],
        [
          ['7'],
          ['8'],
          ['9'],
        ],
        [
          ['.'],
          ['0'],
          [KeyboardClassKey.Bksp]
        ],
      ],
      'lang': ['fr']
    }
  }
}
