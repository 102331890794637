import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {delay, dematerialize, materialize} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {ConfigService} from "../services/config.service";
import {InvocationResult} from "../interfaces/invocation-result";
import {Debit} from "../../interfaces/kiosk-api/debit";

@Injectable()
export class KioskApiInterceptor implements HttpInterceptor {

  success: boolean = true

  constructor(
    private configService: ConfigService
  ) {
    // do nothing
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.kioskApi.baseUrl) || !this.configService.isEmulateDevices()) {
      return next.handle(req)
    } else {
      // wrap in delayed observable to simulate server api call
      return this.handle(req)
        .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
        .pipe(delay(3000))
        .pipe(dematerialize())
    }
  }

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
    const {url, method, headers, body} = req
    switch (true) {
      case url.endsWith('/ticket-printer/print') && method === 'POST':
        return this.print()
      case url.endsWith('/payment-terminal/started') && method === 'GET':
        return this.started()
      case url.endsWith('/payment-terminal/start') && method === 'POST':
        return this.start()
      case url.endsWith('/payment-terminal/debit') && method === 'POST':
        return this.debit(body)
      default:
        return throwError(`This call is not mock: ${method} ${url}`)
    }
  }

  print(): Observable<HttpEvent<any>> {
    const response = {data: 'Print successful'} as InvocationResult
    return this.ok(response)
  }

  started(): Observable<HttpEvent<any>> {
    const response = {data: true} as InvocationResult
    return this.ok(response)
  }

  start(): Observable<HttpEvent<any>> {
    const response = {} as InvocationResult
    return this.ok(response)
  }


  debit(body: any): Observable<HttpEvent<any>> {
    this.success = !this.success

    console.log(`Debit: ${JSON.stringify(body)}`)
    const debit: Debit = {
      successful: true,
      serverTransactionNb: "fake-id",
      bankingServerNb: "1",
      transactionDateTime: new Date().toISOString(),
      ticket: "\r\n\r\n\r\n      CARTE BANCAIRE\r\n      (% img 'ctls.png' %)\r\n      a0000000421010\r\n      CB2\r\n      LE 03-08-22 A 12-09-24\r\n      VERIFONE TEST\r\n      MONTPELLIER\r\n      34000\r\n      11899\r\n      4287348\r\n      38024860900162\r\n      ************1513\r\n      ee50335c97c37f97\r\n      017 001 017002 000099\r\n      C                    @\r\n      No AUTO: TST192\r\n      MONTANT\r\n              9.50        EUR\r\n      DEBIT\r\n      TICKET CLIENT\r\n      A CONSERVER\r\n\r\n\r\n\r\n--/--"
    }
    const response = {data: debit} as InvocationResult
    return this.ok(response)
  }

  ok(body?: any): Observable<HttpEvent<any>> {
    return of(new HttpResponse({status: 200, body: body}))
  }
}
