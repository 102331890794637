import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Collaborator, CollaboratorStatus, StripeKycStatus} from "../../interfaces/collaborator";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class CollaboratorService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper,
  ) {
  }

  getCollaborators(
    search: string = undefined,
    status: CollaboratorStatus = undefined,
    kycStatus: StripeKycStatus = undefined,
    clockedIn: boolean = false
  ): Observable<Collaborator[]> {
    const url = this.appHelper.getApiUrlByType('HTI')
    let queryParams = new HttpParams()
      .set('perPage', 1000)
      .set('page', 0)
      .set('clockedIn', clockedIn)

    if (search) {
      queryParams = queryParams.append("search", search)
    }
    if (status) {
      queryParams = queryParams.append("status", status)
    }
    if (kycStatus) {
      queryParams = queryParams.append("kycStatus", kycStatus)
    }

    return this.httpClient.get(url + '/collaborators', {params: queryParams}).pipe(
      map((result: InvocationResult) => {
        return result.data
      })
    )
  }

  getClockInToken(): Observable<string> {
    const url = this.appHelper.getApiUrlByType('HTI')
    return this.httpClient.get(url + '/collaborators/clockInToken', {responseType: "text"}).pipe(
      map((token: string) => {
        return token
      })
    )
  }
}
