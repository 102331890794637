import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, retry} from "rxjs";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {map} from "rxjs/operators";
import {PatchPayment} from "../../interfaces/patch-payment";
import {Payment} from "../../interfaces/payment";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  patch(paymentId: string, patchPayment: PatchPayment): Observable<Payment> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.patch<InvocationResult>(url + '/payments/' + paymentId, patchPayment)
      .pipe(
        map((result: InvocationResult) => result.data),
        retry({count: 5, delay: 2000})
      )
  }
}
