import {Injectable} from "@angular/core";
import {environment} from "src/environments/environment";

@Injectable({providedIn: 'root'})
export class ConfigService {

  private emulateDevices = true

  constructor() {
    // do nothing
  }

  setEmulateDevices(emulateDevices: boolean) {
    this.emulateDevices = emulateDevices
    if (this.emulateDevices) {
      console.log("devices (printer and payment terminal) are emulated")
    }
  }

  isEmulateDevices() {
    return !environment.production && this.emulateDevices
  }
}
