import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Reason} from "../../interfaces/reason";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class ReasonService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
    // do nothing
  }


  getReasons(): Observable<Reason[]> {
    const url = this.appHelper.getApiUrlByType('HTI')

    const queryParams = new HttpParams()
      .set('perPage', '25')
      .set('page', '0');

    return this.httpClient.get(url + '/reasons', {params: queryParams})
      .pipe(map((res: InvocationResult) => {
          return res.data
        })
      );
  }
}
