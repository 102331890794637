<div *ngIf="theme" class="home" (click)="userActionOnScreen()">
  <div class="home_header">
    <div class="home_header_logo">
      <img *ngIf="logo" class="home_header_logo_source" [src]="logo.url" (click)="adminAccessButtonClicked()"/>
      <img *ngIf="!logo" class="home_header_logo_source" src="assets/images/icons/icon-humetips.svg"
           (click)="adminAccessButtonClicked()"/>
    </div>
    <div class="home_header_right-side">
      <div class="home_header_right-side_tip-text"
           [innerHtml]="theme.title | dynamicTranslation: this.selectedLanguage.code | classByChar: 'text-gradient-letter'">
      </div>
      <div *ngIf="languageList.length > 1" class="home_header_right-side_language-btn" (click)="showLanguageSelector()">
        <img class="home_header_right-side_language-btn_flag"
             [src]="'assets/images/flags/' + selectedLanguage.code + '.svg'"/>
        <div class="home_header_right-side_language-btn_text"
             [innerHtml]="selectedLanguage.nativeName | classByChar: 'button-text-gradient-letter'">
        </div>
      </div>
    </div>
  </div>
  <div class="home_body">
    <div class="home_body_subtitle">
      <div class="home_body_subtitle_text">
        <div
          [innerHtml]="theme.subtitle | dynamicTranslation: this.selectedLanguage.code | classByChar: 'text-gradient-letter'"></div>
      </div>
    </div>
    <ng-container *ngIf="theme.swapLayout">
      <app-humetips-selector
        class="humetips-selector_up"
        *ngIf="!settings.enableUniqueRecipient"
        [selectedLanguage]="selectedLanguage" [settings]="settings" [theme]="theme"
        [collaborators]="collaborators" [teams]="teams" [resetComponent]="resetHumetipsSelector"
        (collaboratorTypeChanged)="changeCollaboratorType($event)"
        (collaboratorClicked)="collaboratorHasBeenClicked($event)"
        (teamClicked)="teamHasBeenClicked($event)">
      </app-humetips-selector>
    </ng-container>
    <div class="home_body_preselected-tips" *ngIf="settings.enablePredefinedAmounts">
      <div class="home_body_preselected-tips_list">
        <div class="home_body_preselected-tips_list_amount" *ngFor="let amount of amounts.slice(0,amountsSliceNbr)"
             (click)="setPreselectedTipValue(amount.value)">
          <div class="home_body_preselected-tips_list_amount_text"
               [innerHtml]="(amount.value + '€') | classByChar: 'button-text-gradient-letter'">
          </div>
        </div>
        <div *ngIf="amounts.length > 4" class="home_body_preselected-tips_list_amount"
             (click)="setPreselectedTipValue(-1)">
          <div class="home_body_preselected-tips_list_amount_text"
               [innerHtml]="'+' | classByChar: 'button-text-gradient-letter'">
          </div>
        </div>
      </div>
    </div>
    <div class="home_body_tip">
      <div *ngIf="!settings.enableUniqueAmount" class="home_body_tip_button" (click)="changeTipValue(Action.MINUS)">
        <div class="home_body_tip_button_text"
             [innerHtml]="'-' | classByChar: 'button-text-gradient-letter'">
        </div>
      </div>
      <div class="home_body_tip_amount" (click)="showAmountKeyboard()">
        <div *ngIf="!amountKeyboardVisible" class="home_body_tip_amount_text"
             [class.home_body_tip_amount_text--high-value]="tipValue >= 1000"
             [innerHtml]="((tipValue | number: '1.2-2') + '€') | classByChar: 'button-text-gradient-letter'">
        </div>
        <div *ngIf="amountKeyboardVisible" class="home_body_tip_amount_text"
             [class.home_body_tip_amount_text--high-value]="customTipValue >= 1000"
             [innerHtml]="customTipValueStr | classByChar: 'button-text-gradient-letter'">
        </div>
      </div>
      <div *ngIf="!settings.enableUniqueAmount" class="home_body_tip_button" (click)="changeTipValue(Action.PLUS)">
        <div class="home_body_tip_button_text"
             [innerHtml]="'+' | classByChar: 'button-text-gradient-letter'">
        </div>
      </div>
    </div>
    <div *ngIf="settings.enablePaymentReasons" class="home_body_reasons">
      <div class="home_body_reasons_header"
           [innerHtml]="theme.paymentReasonLabel | dynamicTranslation: this.selectedLanguage.code | classByChar: 'text-gradient-letter'">
      </div>
      <div class="home_body_reasons_list">
        <app-reason *ngFor="let reason of reasons" [reason]="reason" (clicked)="reasonClicked($event)"
                    [languageCode]="this.selectedLanguage.code" [selected]="reason.id == selectedReason?.id">
        </app-reason>
      </div>
      <div class="home_body_reasons_custom">
        <form [formGroup]="customReasonForm" class="home_body_reasons_custom_field">
          <input *ngIf="showApplicationKeyboard" formControlName="reason" type="text"
                 [matKeyboard]="'keyboard_complete_fr'"
                 (enterClick)="hideReasonKeyboard()" (click)="customReasonChanged()"
                 (ngModelChange)="customReasonChanged()"
                 #reasonInput>
          <input *ngIf="!showApplicationKeyboard" formControlName="reason" type="text" (click)="customReasonChanged()"
                 (ngModelChange)="customReasonChanged()">
        </form>
      </div>
    </div>
    <div class="home_body_tip-description" *ngIf="settings.enableUniqueRecipient">
      <div class="home_body_tip-description_value"
           [innerHtml]="recipientLabel | classByChar: 'text-gradient-letter'">
      </div>
    </div>
    <ng-container *ngIf="!theme.swapLayout">
      <app-humetips-selector
        *ngIf="!settings.enableUniqueRecipient"
        [selectedLanguage]="selectedLanguage" [settings]="settings" [theme]="theme"
        [collaborators]="collaborators" [teams]="teams" [resetComponent]="resetHumetipsSelector"
        (collaboratorTypeChanged)="changeCollaboratorType($event)"
        (collaboratorClicked)="collaboratorHasBeenClicked($event)"
        (teamClicked)="teamHasBeenClicked($event)">
      </app-humetips-selector>
    </ng-container>
  </div>
  <div class="home_footer">
    <div *ngIf="getValidateButtonStatus()" class="home_footer_ask-documents">
      <div *ngIf="settings.enableUniqueRecipient && !settings.forbidCerfaUsage"
           class="home_footer_ask-documents_element">
        <input type="checkbox" [checked]="askForCerfa"
               (change)="paymentDocumentChanged(PaymentDocuments.CERFA, $event)"/>
        <span [innerHtml]="'APP.HOME.DOCUMENTS.CERFA' | translate | classByChar: 'text-gradient-letter'">
        </span>
      </div>
      <div class="home_footer_ask-documents_element">
        <input type="checkbox" [checked]="askForTicket"
               (change)="paymentDocumentChanged(PaymentDocuments.TICKET, $event)"/>
        <span [innerHtml]="'APP.HOME.DOCUMENTS.TICKET' | translate | classByChar: 'text-gradient-letter'">
        </span>
      </div>
    </div>
    <div class="home_footer_decoration-bottom"
         [innerHtml]="'\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\' | classByChar: 'text-gradient-letter'">
    </div>
    <div class="home_footer_button" *ngIf="getValidateButtonStatus()" (click)="validate()">
      <div class="home_footer_button_validate"
           [innerHtml]="'APP.HOME.BUTTONS.VALID' | translate | classByChar: 'button-text-gradient-letter'">
      </div>
    </div>
  </div>
  <div class="home_fixed-type" *ngIf="showAppType">
    {{ appType }}
  </div>
</div>
<div class="loading" *ngIf="!theme">
  <div class="loading_text">
    {{ 'APP.HOME.LOADING' | translate }}
  </div>
</div>
<div class="payment-dialog" *ngIf="paymentStatus != PaymentStepStatus.NONE">
  <div class="payment-dialog_box">
    <div class="payment-dialog_box_card" *ngIf="paymentStatus == PaymentStepStatus.WAITING">
      <div class="payment-dialog_box_card_text"
           [innerHtml]="'APP.PAYMENT.KIOSK.PRESENT_CARD' | translate | classByChar: 'button-text-gradient-letter'">
      </div>
      <div class="payment-dialog_box_card_price"
           [innerHtml]="((tipValue | number: '1.2-2') + '€') | classByChar: 'button-text-gradient-letter'">
      </div>
      <svg class="payment-dialog_box_card_logo">
        <use class="payment-dialog_box_card_logo_source" xlink:href='assets/images/contactless.svg#logo'></use>
      </svg>
      <div class="payment-dialog_box_card_indications">
        <mat-icon class="payment-dialog_box_card_indications_icon">
          arrow_downward
        </mat-icon>
        <mat-icon class="payment-dialog_box_card_indications_icon">
          arrow_downward
        </mat-icon>
        <mat-icon class="payment-dialog_box_card_indications_icon">
          arrow_downward
        </mat-icon>
      </div>
    </div>
    <div class="payment-dialog_box_result" *ngIf="paymentStatus != PaymentStepStatus.WAITING">
      <div class="payment-dialog_box_result_success" *ngIf="paymentStatus == PaymentStepStatus.SUCCESS">
        <div class="payment-dialog_box_result_success_payment-icon">
          <div *ngIf="!settings.enableUniqueRecipient">{{ 'APP.PAYMENT.RESULTS.PAYMENT_SUCCESSFUL' | translate }}</div>
          <div
            *ngIf="settings.enableUniqueRecipient">{{ 'APP.PAYMENT.RESULTS.DONATION_SUCCESSFUL' | translate }}
          </div>
          <mat-icon>done</mat-icon>
        </div>
        <div class="payment-dialog_box_result_success_logo">
          <img *ngIf="logo" class="payment-dialog_box_result_success_logo_source" [src]="logo.thumbnailUrl"/>
        </div>
        <div class="payment-dialog_box_result_success_thanks">
          <div>{{ 'APP.PAYMENT.RESULTS.THANKS' | translate }}</div>
        </div>
      </div>
      <div class="payment-dialog_box_result_failure" *ngIf="paymentStatus == PaymentStepStatus.FAILURE">
        <span>{{ 'APP.PAYMENT.RESULTS.ERROR' | translate }}</span>
        <div class="payment-dialog_box_result_failure_buttons">
          <div class="payment-dialog_box_result_failure_buttons_btn" (click)="retryAfterFailedPayment()">
            {{ 'APP.PAYMENT.RESULTS.BUTTONS.TRY_AGAIN' | translate }}
          </div>
          <div class="payment-dialog_box_result_failure_buttons_btn" (click)="cancelAfterFailedPayment()">
            {{ 'APP.PAYMENT.RESULTS.BUTTONS.CANCEL' | translate }}
          </div>
          <div *ngIf="isVerifonePaymentType && !failedTipAlreadyRegistered"
               class="payment-dialog_box_result_failure_buttons_btn"
               (click)="askForTicketAfterFailedPayment()">
            {{ 'APP.PAYMENT.RESULTS.BUTTONS.RECOVER_TICKET' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isVerifonePaymentType && verifoneCancelPopupVisible" class="payment-dialog_verifone-cancel">
    <div class="payment-dialog_verifone-cancel_text">
      <span class="payment-dialog_verifone-cancel_text_title">
        {{ 'APP.PAYMENT.KIOSK.VERIFONE_CANCEL_POPUP.TITLE' | translate }}
      </span>
      <span class="payment-dialog_verifone-cancel_text_description">
        {{ 'APP.PAYMENT.KIOSK.VERIFONE_CANCEL_POPUP.DESCRIPTION' | translate }}
      </span>
    </div>
    <img src="assets/images/terminals/terminal-verifone.png"/>
  </div>
</div>


<app-amount-keyboard *ngIf="amountKeyboardVisible" (goBackEvent)="hideAmountKeyboard()"
                     (keyPressed)="amountKeyboardKeyPressed($event)">
</app-amount-keyboard>

<app-language-selector *ngIf="languageSelectorVisible" [languages]="languageList"
                       (clicked)="languageSelectorClicked($event)">
</app-language-selector>

<app-admin *ngIf="showAdmin" (goBackEvent)="changeAdminMenuState(false)" [settings]="settings"
           (resetLocalStorageEvent)="resetLocalstorage()">
</app-admin>

<app-error *ngIf="errorMode" [errorReason]="errorValue" (errorScreenClickEvent)="errorScreenClicked()">
</app-error>

<app-feedback *ngIf="currentAppStep == AppStep.FEEDBACK" [logo]="logo" [theme]="theme"
              (showTipScreen)="handleScreens($event)" [selectedLanguage]="this.selectedLanguage">
</app-feedback>

<app-app-selection *ngIf="theme && theme.enableAppSelection && currentAppStep == AppStep.CUSTOM_URL" [theme]="theme"
                   (humetipsAppClicked)="handleScreens('forward')" (customerAppClicked)="clearCustomerSiteCache=true"
                   (leaveCustomerAppClicked)="reset()">
</app-app-selection>

<div class="app-preselected-amounts-container"
     [class.app-preselected-amounts-container--show]="showAllPreselectedAmount">
  <app-preselected-amounts [amounts]="amounts" (onAmountClicked)="setPreselectedTipValue($event)">
  </app-preselected-amounts>
</div>

<ng-container
  *ngIf="theme && currentAppStep == AppStep.SCREENSAVER">
  <div class="screensaver" (click)="leaveScreensaver()">
    <img [src]="currentScreensaver?.url"/>
  </div>
</ng-container>
