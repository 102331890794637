import {Injectable} from '@angular/core';
import {Theme} from "../../../interfaces/theme";
import {Settings} from "../../../interfaces/settings";
import {Language} from "../../../interfaces/language";

@Injectable({
  providedIn: 'root'
})
export class LocalstorageHelper {

  constructor() {
    // do nothing
  }

  getLocalStorageDeviceId(): string {
    return localStorage.getItem('hti-device-id')
  }

  setLocalStorageDeviceId(cid: string) {
    localStorage.setItem('hti-device-id', cid)
  }

  getLocalStorageCid(): string {
    return localStorage.getItem('hti-cid')
  }

  setLocalStorageCid(cid: string) {
    localStorage.setItem('hti-cid', cid)
  }

  getLocalStorageStripeTerminalId(): string {
    return localStorage.getItem('hti-stripe-sn')
  }

  setLocalStorageStripeTerminalSN(stid: string) {
    localStorage.setItem('hti-stripe-sn', stid)
  }

  getLocalStorageTheme(): Theme {
    return JSON.parse(localStorage.getItem('hti-theme'))
  }

  setLocalStorageTheme(theme: Theme) {
    localStorage.setItem('hti-theme', JSON.stringify(theme))
  }

  getLocalStorageSettings(): Settings {
    return JSON.parse(localStorage.getItem('hti-theme'))
  }

  setLocalStorageSettings(settings: Settings) {
    localStorage.setItem('hti-settings', JSON.stringify(settings))
  }

  getLocalStorageLanguage(): Language {
    return JSON.parse(localStorage.getItem('hti-selected-language'))
  }

  setLocalStorageLanguage(language: Language) {
    localStorage.setItem('hti-language', JSON.stringify(language))
  }

  getLocalInstance(): string {
    return localStorage.getItem('hti-instance')
  }

  setLocalInstance(instance: string) {
    localStorage.setItem('hti-instance', instance)
  }
}
