import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Language} from "../../interfaces/language";
import {first, map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Observable} from "rxjs";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
    // do nothing
  }

  getLanguages(): Observable<Language[]> {
    const url = this.appHelper.getApiUrlByType('CMN_APP')

    return this.httpClient
      .get(url + '/languages/')
      .pipe(
        map((res: InvocationResult) => {
          return res.data;
        }),
        first()
      );
  }
}
