<div class="customer-details">
  <div class="customer-details_header">
    <!-- FILLING INFORMATIONS TITLES -->
    <div [ngSwitch]="datas.askedDocuments" *ngIf="step == Steps.FILLING_INFORMATIONS">
      <div *ngSwitchCase="PaymentDocuments.CERFA" class="customer-details_header_title">
        {{ 'APP.DOCUMENTS.CERFA.TITLE' | translate }}
      </div>
      <div *ngSwitchCase="PaymentDocuments.TICKET" class="customer-details_header_title">
        {{ 'APP.DOCUMENTS.TICKET.TITLE' | translate }}
      </div>
      <div *ngSwitchCase="PaymentDocuments.CERFA_AND_TICKET" class="customer-details_header_title">
        {{ 'APP.DOCUMENTS.BOTH.TITLE' | translate }}
      </div>
    </div>
    <div [ngSwitch]="datas.askedDocuments" *ngIf="step == Steps.FILLING_INFORMATIONS">
      <div *ngSwitchCase="PaymentDocuments.CERFA" [innerHTML]="'APP.DOCUMENTS.CERFA.SUBTITLE' | translate"
           class="customer-details_header_subtitle">
      </div>
      <div *ngSwitchCase="PaymentDocuments.TICKET" [innerHTML]="'APP.DOCUMENTS.TICKET.SUBTITLE' | translate"
           class="customer-details_header_subtitle">
      </div>
      <div *ngSwitchCase="PaymentDocuments.CERFA_AND_TICKET" [innerHTML]="'APP.DOCUMENTS.BOTH.SUBTITLE' | translate"
           class="customer-details_header_subtitle">
      </div>
    </div>
    <!-- PROCESSING -->
    <div *ngIf="step == Steps.PROCESSING">
      <div class="customer-details_header_title">
        {{ 'APP.DOCUMENTS.EMAIL.SENDING' | translate }}
      </div>
      <div [ngSwitch]="datas.askedDocuments">
        <div *ngSwitchCase="PaymentDocuments.CERFA" class="customer-details_header_subtitle">
          {{ 'APP.DOCUMENTS.EMAIL.SUBTITLES.CERFA' | translate }}
        </div>
        <div *ngSwitchCase="PaymentDocuments.TICKET" class="customer-details_header_subtitle">
          {{ 'APP.DOCUMENTS.EMAIL.SUBTITLES.TICKET' | translate }}
        </div>
        <div *ngSwitchCase="PaymentDocuments.CERFA_AND_TICKET" class="customer-details_header_subtitle">
          {{ 'APP.DOCUMENTS.EMAIL.SUBTITLES.CERFA_AND_TICKET' | translate }}
        </div>
      </div>
    </div>
    <div *ngIf="step == Steps.SUCCESS">
      <div class="customer-details_header_title">
        {{ 'APP.DOCUMENTS.EMAIL.SUCCESS' | translate }}
      </div>
    </div>
  </div>
  <div class="customer-details_form" *ngIf="step == Steps.FILLING_INFORMATIONS" [formGroup]="customerDetailsForm">
    <mat-form-field *ngIf="datas.askedDocuments != PaymentDocuments.TICKET" class="customer-details_form_field"
                    appearance="outline">
      <mat-label>{{ 'APP.DOCUMENTS.NAME_FIELD' | translate }}</mat-label>
      <input *ngIf="showApplicationKeyboard" formControlName="name" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.NAME_FIELD' | translate" [matKeyboard]="'keyboard_simple_fr'"
             (input)="onInteractionDetected()" required>
      <input *ngIf="!showApplicationKeyboard" formControlName="name" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.NAME_FIELD' | translate" (input)="onInteractionDetected()" required>
    </mat-form-field>
    <mat-error *ngIf="(customerDetailsForm.get('name').statusChanges | async) == 'INVALID'">
      <app-validator-errors [errors]="customerDetailsForm.get('name').errors">
      </app-validator-errors>
    </mat-error>

    <mat-form-field class="customer-details_form_field" appearance="outline">
      <mat-label>{{ 'APP.DOCUMENTS.EMAIL_FIELD' | translate }}</mat-label>
      <input *ngIf="showApplicationKeyboard" formControlName="email" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.EMAIL_FIELD' | translate" [matKeyboard]="'keyboard_email_fr'"
             email="true" (input)="onInteractionDetected()" required>
      <input *ngIf="!showApplicationKeyboard" formControlName="email" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.EMAIL_FIELD' | translate" email="true" (input)="onInteractionDetected()"
             required>
    </mat-form-field>
    <mat-error *ngIf="(customerDetailsForm.get('email').statusChanges | async) == 'INVALID'">
      <app-validator-errors [errors]="customerDetailsForm.get('email').errors">
      </app-validator-errors>
    </mat-error>

    <mat-form-field *ngIf="datas.askedDocuments != PaymentDocuments.TICKET" class="customer-details_form_field"
                    appearance="outline">
      <mat-label>{{ 'APP.DOCUMENTS.ADDRESS_FIELD' | translate }}</mat-label>
      <input *ngIf="showApplicationKeyboard" formControlName="address" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.ADDRESS_FIELD' | translate" [matKeyboard]="'keyboard_simple_fr'"
             (input)="onInteractionDetected()" required>
      <input *ngIf="!showApplicationKeyboard" formControlName="address" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.ADDRESS_FIELD' | translate" (input)="onInteractionDetected()" required>
    </mat-form-field>
    <mat-error *ngIf="(customerDetailsForm.get('address').statusChanges | async) == 'INVALID'">
      <app-validator-errors [errors]="customerDetailsForm.get('address').errors">
      </app-validator-errors>
    </mat-error>

    <mat-form-field *ngIf="datas.askedDocuments != PaymentDocuments.TICKET" class="customer-details_form_field"
                    appearance="outline">
      <mat-label>{{ 'APP.DOCUMENTS.POSTAL_CODE_FIELD' | translate }}</mat-label>
      <input *ngIf="showApplicationKeyboard" formControlName="postalCode" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.POSTAL_CODE_FIELD' | translate" [matKeyboard]="'keyboard_amount'"
             (input)="onInteractionDetected()" required>
      <input *ngIf="!showApplicationKeyboard" formControlName="postalCode" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.POSTAL_CODE_FIELD' | translate" (input)="onInteractionDetected()" required>
    </mat-form-field>
    <mat-error *ngIf="(customerDetailsForm.get('postalCode').statusChanges | async) == 'INVALID'">
      <app-validator-errors [errors]="customerDetailsForm.get('postalCode').errors">
      </app-validator-errors>
    </mat-error>

    <mat-form-field *ngIf="datas.askedDocuments != PaymentDocuments.TICKET" class="customer-details_form_field"
                    appearance="outline">
      <mat-label>{{ 'APP.DOCUMENTS.CITY_FIELD' | translate }}</mat-label>
      <input *ngIf="showApplicationKeyboard" formControlName="city" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.CITY_FIELD' | translate" [matKeyboard]="'keyboard_simple_fr'"
             (input)="onInteractionDetected()" required>
      <input *ngIf="!showApplicationKeyboard" formControlName="city" type="text" matInput
             [placeholder]="'APP.DOCUMENTS.CITY_FIELD' | translate" (input)="onInteractionDetected()" required>
    </mat-form-field>
    <mat-error *ngIf="(customerDetailsForm.get('city').statusChanges | async) == 'INVALID'">
      <app-validator-errors [errors]="customerDetailsForm.get('city').errors">
      </app-validator-errors>
    </mat-error>

  </div>
  <div class="customer-details_footer" *ngIf="step == Steps.FILLING_INFORMATIONS">
    <button mat-raised-button class="customer-details_footer_valid-button" (click)="validate()"
            [disabled]="!customerDetailsForm.valid">
      <div>
        {{ 'APP.DOCUMENTS.VALIDATE' | translate }}
      </div>
    </button>
    <button mat-raised-button class="customer-details_footer_cancel-button" (click)="cancel()">
      <div>
        {{ 'APP.DOCUMENTS.CANCEL' | translate }}
      </div>
    </button>
  </div>
  <div class="customer-details_loading" *ngIf="step == Steps.PROCESSING">
    <mat-spinner>
    </mat-spinner>
  </div>
  <div class="customer-details_success" *ngIf="step == Steps.SUCCESS">
    <mat-icon>
      thumb_up
    </mat-icon>
  </div>
</div>
