<ng-container *ngIf="errors && errors['min']">
  <span>{{ "VALIDATORS.MIN" | translate:{ "required": errors['min'].required } }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['max']">
  <span>{{ "VALIDATORS.MAX" | translate:{ "required": errors['max'].required } }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['required']">
  <span>{{ "VALIDATORS.REQUIRED" | translate }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['requiredTrue']">
  <span>{{ "VALIDATORS.REQUIRED_TRUE" | translate }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['email']">
  <span>{{ "VALIDATORS.EMAIL" | translate }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['minlength']">
  <span>{{ "VALIDATORS.MIN_LENGTH" | translate:{ "requiredLength": errors['minlength'].requiredLength } }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['maxlength']">
  <span>{{ "VALIDATORS.MAX_LENGTH" | translate:{ "requiredLength": errors['maxlength'].requiredLength } }}</span>
</ng-container>
<ng-container *ngIf="errors && errors['pattern']">
  <span>{{ "VALIDATORS.PATTERN" | translate }}</span>
</ng-container>
