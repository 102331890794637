import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {MediaService} from "../../../services/medias/media.service";
import {Team} from "../../../interfaces/team";

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  subscriptions: Subscription = new Subscription()

  @Output() clicked: EventEmitter<Team> = new EventEmitter()
  @Input() team: Team
  @Input() selected: boolean

  iconMediaId: string = null

  constructor(
    private mediaService: MediaService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    if (this.team) {
      this.retrieveMedia(this.team.iconMediaId)
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  selectTeam() {
    this.clicked.emit(this.team)
  }

  retrieveMedia(iconMediaId: string): void {
    if (iconMediaId) {
      this.subscriptions.add(
        this.mediaService.getMedia(iconMediaId).subscribe(media => {
          this.iconMediaId = media.thumbnailUrl;
        })
      );
    }
  }
}
