import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ErrorType} from "../../interfaces/error.type";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Output() errorScreenClickEvent: EventEmitter<any> = new EventEmitter<any>()

  @Input() errorReason: ErrorType = ErrorType.DEFAULT
  ErrorType: typeof ErrorType = ErrorType;

  constructor() {
    // do nothing
  }

  errorScreenClicked() {
    this.errorScreenClickEvent.emit()
  }
}
