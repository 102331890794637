import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-validator-errors',
  templateUrl: './validator-errors.component.html',
  styleUrls: ['./validator-errors.component.scss']
})
export class ValidatorErrorsComponent {

  @Input() errors: ValidationErrors

  constructor() { }
}
