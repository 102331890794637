<div class="tip-description" *ngIf="!settings.enableUniqueRecipient">
  <div class="tip-description_value"
       [innerHtml]="theme.tipLabel | dynamicTranslation: this.selectedLanguage.code | classByChar: 'text-gradient-letter'">
  </div>
</div>
<div class="humetips-selector">

  <!-- TEAMS -->
  <div class="humetips-selector_container" *ngIf="!settings.enableUniqueRecipient && teams.length > 0"
       (click)="changeCollaboratorType(CollaboratorType.TEAM)"
       [class.humetips-selector_container--selected]="collaboratorType == CollaboratorType.TEAM">
    <div class="humetips-selector_container_text"
         [class.humetips-selector_container_text--selected]="collaboratorType == CollaboratorType.TEAM"
         [innerHtml]="'APP.HOME.TEAM' | translate | classByChar: collaboratorType == CollaboratorType.TEAM ? 'button-text-gradient-letter' : 'button-background-gradient-letter'">
    </div>
    <div class="humetips-selector_container_checkbox"
         [class.humetips-selector_container_checkbox--selected]="collaboratorType == CollaboratorType.TEAM">
      <div class="humetips-selector_container_checkbox_check"
           *ngIf="collaboratorType == CollaboratorType.TEAM">
      </div>
    </div>
  </div>
  <div class="humetips-selector_list" *ngIf="collaboratorType == CollaboratorType.TEAM">
    <div class="humetips-selector_list_navigation" (click)="changeTeamPageValue(Action.MINUS)"
         [class.humetips-selector_list_navigation--disabled]="minTeamPageReach">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="humetips-selector_list_items">
      <app-team *ngFor="let team of displayedTeams" [team]="team" [selected]="team.id == selectedTeam?.id"
                (clicked)="teamHasBeenClicked($event)">
      </app-team>
    </div>
    <div class="humetips-selector_list_navigation" (click)="changeTeamPageValue(Action.PLUS)"
         [class.humetips-selector_list_navigation--disabled]="maxTeamPageReach">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>

  <!-- COLLABORATORS -->
  <div class="humetips-selector_container" *ngIf="!settings.enableUniqueRecipient"
       (click)="changeCollaboratorType(CollaboratorType.EMPLOYEE)"
       [class.humetips-selector_container--selected]="collaboratorType == CollaboratorType.EMPLOYEE">
    <div class="humetips-selector_container_text"
         [class.humetips-selector_container_text--selected]="collaboratorType == CollaboratorType.EMPLOYEE"
         [innerHtml]="'APP.HOME.EMPLOYEE' | translate | classByChar: collaboratorType == CollaboratorType.EMPLOYEE ? 'button-text-gradient-letter' : 'button-background-gradient-letter'">
    </div>
    <div class="humetips-selector_container_checkbox"
         [class.humetips-selector_container_checkbox--selected]="collaboratorType == CollaboratorType.EMPLOYEE">
      <div class="humetips-selector_container_checkbox_check"
           *ngIf="collaboratorType == CollaboratorType.EMPLOYEE">
      </div>
    </div>
  </div>
  <div class="humetips-selector_list" *ngIf="collaboratorType == CollaboratorType.EMPLOYEE">
    <div class="humetips-selector_list_navigation" (click)="changeCollaboratorPageValue(Action.MINUS)"
         [class.humetips-selector_list_navigation--disabled]="minCollaboratorPageReach">
      <mat-icon>chevron_left</mat-icon>
    </div>
    <div class="humetips-selector_list_items">
      <app-collaborator *ngFor="let collaborator of displayedCollaborators" [collaborator]="collaborator"
                        [selected]="isCollaboratorSelected(collaborator.id)"
                        (clicked)="collaboratorHasBeenClicked($event)">
      </app-collaborator>
    </div>
    <div class="humetips-selector_list_navigation" (click)="changeCollaboratorPageValue(Action.PLUS)"
         [class.humetips-selector_list_navigation--disabled]="maxCollaboratorPageReach">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
</div>
