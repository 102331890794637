import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StripeApiService as CustomStripeService} from "../../services/stripe-api/stripe-api.service"

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StripeElementChangeEvent} from "@stripe/stripe-js/types/stripe-js/elements/base";
import {of, Subscription} from "rxjs";
import {ThemeHelper} from "../../shared/helpers/themes/theme.helper";
import {CreatePaymentIntentDto} from "../../interfaces/dto/create-payment-intent-dto";
import {StripeModalData, StripeModalResult, StripeResult} from "../../interfaces/stripe-modal.data";
import {PaymentStepStatus} from "../../interfaces/payment-step.status";
import {PaymentIntent} from 'src/app/interfaces/payment-intent';
import {
  PaymentRequestOptions,
  PaymentRequestPaymentMethodEvent,
  StripeCardElementOptions,
  StripeElementsOptions
} from "@stripe/stripe-js";
import {StripeCardComponent, StripeService} from "ngx-stripe";
import {map, switchMap} from "rxjs/operators";

@Component({
  selector: 'app-stripe-payment-modal',
  templateUrl: './stripe-payment-modal.component.html',
  styleUrls: ['./stripe-payment-modal.component.scss']
})
export class StripePaymentModalComponent implements OnInit {

  elementsOptions: StripeElementsOptions = {
    locale: 'fr'
  };

  paymentRequestOptions: PaymentRequestOptions = {
    country: 'FR',
    currency: 'eur',
    total: {
      label: `Pourboire`,
      amount: 0,
    },
    requestPayerName: true,
    requestPayerEmail: true,
    disableWallets: ["link"]
  };

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          fontSize: '18px',
          color: '#CFD7E0'
        }
      }
    }
  };

  get validForm() {
    return this.paymentForm.valid && this.stripeCardValid
  }

  @ViewChild(StripeCardComponent) card: StripeCardComponent
  PaymentStepStatus: typeof PaymentStepStatus = PaymentStepStatus
  subscriptions: Subscription = new Subscription()

  stripeCardValid: boolean = false;
  paymentForm: FormGroup;

  tipValue: number = 0
  isUniqueRecipient: boolean = false

  paymentStatus: PaymentStepStatus = PaymentStepStatus.NONE

  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private customStripeService: CustomStripeService,
    private themeHelper: ThemeHelper,
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<StripePaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StripeModalData
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.themeHelper.loadTheme(this.elementRef.nativeElement)

    this.tipValue = this.data.value
    this.isUniqueRecipient = this.data.isUniqueRecipient

    this.paymentStatus = PaymentStepStatus.NONE

    this.paymentForm = this.fb.group({
      name: ['', [Validators.required]]
    });

    this.paymentRequestOptions.total.amount = this.tipValue * 100
  }

  onChange(event: StripeElementChangeEvent) {
    this.stripeCardValid = event.complete
  }

  pay() {
    this.paymentStatus = PaymentStepStatus.WAITING

    let createPaymentIntent: CreatePaymentIntentDto = {
      price: this.tipValue.toString(),
      kioskPayment: false
    }

    this.subscriptions.add(
      this.customStripeService.createPaymentIntent(createPaymentIntent).subscribe({
        next: (paymentIntent) => {
          this.createCard(paymentIntent)
        },
        error: () => {
          this.paymentStatus = PaymentStepStatus.FAILURE
        }
      })
    )
  }

  createCard(paymentIntent: PaymentIntent) {
    this.subscriptions.add(
      this.stripeService.createPaymentMethod({
        card: this.card.element,
        billing_details: {
          name: this.paymentForm.get('name').value
        },
        type: 'card'
      }).subscribe({
        next: (cardResult) => {
          if (!cardResult.error) {
            this.confirmCardPayment(paymentIntent, cardResult.paymentMethod.id)
          } else {
            this.paymentStatus = PaymentStepStatus.FAILURE
          }
        },
        error: (error) => {
          this.paymentStatus = PaymentStepStatus.FAILURE
        }
      })
    )
  }

  confirmCardPayment(paymentIntent: PaymentIntent, cardId: string) {
    this.subscriptions.add(
      this.customStripeService.confirmPayment(paymentIntent.stripePaymentIntentId, cardId).subscribe({
        next: (data) => {
          this.OnPaymentSuccessful(data, paymentIntent);
        },
        error: () => {
          this.paymentStatus = PaymentStepStatus.FAILURE
        }
      })
    )
  }

  onPaymentMethod(event: PaymentRequestPaymentMethodEvent) {
    this.customStripeService.createPaymentIntent({
      price: this.tipValue.toString(),
      kioskPayment: false
    }).pipe(
      switchMap((htiPaymentIntent) => {
        return this.stripeService.confirmCardPayment(
          htiPaymentIntent.stripeClientSecret,
          {payment_method: event.paymentMethod.id},
          {handleActions: false}
        ).pipe(
          switchMap((confirmResult) => {
            if (confirmResult.error) {
              event.complete('fail');
              return of({
                error: new Error('Error Confirming the payment'),
              })
            } else {
              event.complete('success');
              return this.stripeService.confirmCardPayment(
                htiPaymentIntent.stripeClientSecret
              )
            }
          })
        ).pipe(
          map((result) => {
            return {
              htiPaymentIntent: htiPaymentIntent,
              result: result
            }
          })
        )
      })
    ).subscribe((data) => {
      if (data.result.error) {
        this.paymentStatus = PaymentStepStatus.FAILURE;
      } else if ((data.result as StripeResult).paymentIntent && data.htiPaymentIntent) {
        this.OnPaymentSuccessful((data.result as StripeResult).paymentIntent, data.htiPaymentIntent);
      } else {
        this.paymentStatus = PaymentStepStatus.FAILURE;
      }
    })
  }

  OnPaymentSuccessful(stripePaymentIntent: any, htiPaymentIntent: PaymentIntent = null) {
    this.paymentStatus = PaymentStepStatus.SUCCESS

    setTimeout(() => {
      let stripeModalResult: StripeModalResult = {
        htiPaymentIntent: htiPaymentIntent,
        stripePaymentIntent: stripePaymentIntent,
        cardHolderName: this.paymentForm.get('name').value
      }
      this.dialogRef.close(stripeModalResult)
    }, 5000);
  }
}
