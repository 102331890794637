import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Media, QueryScope} from "../../shared/interfaces/media";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
    // do nothing
  }

  getMedia(id: string): Observable<Media> {
    const url = this.appHelper.getApiUrlByType('MEDIAS')

    let queryParams = new HttpParams()
      .set('queryScope', QueryScope.CUSTOMER_AND_ANCESTORS)

    return this.httpClient.get(`${url}/medias/${id}`, {params: queryParams})
      .pipe(map((res: InvocationResult) => {
          return res.data
        })
      )
  }
}
