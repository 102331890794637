import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Reason} from "../../interfaces/reason";

@Component({
  selector: 'app-reason',
  templateUrl: './reason.component.html',
  styleUrls: ['./reason.component.scss']
})
export class ReasonComponent {

  @Output() clicked: EventEmitter<Reason> = new EventEmitter()
  @Input() reason: Reason
  @Input() selected: boolean
  @Input() languageCode: string = "fr"

  constructor() {
    // do nothing
  }

  selectReason() {
    this.clicked.emit(this.reason)
  }
}
