export interface Device {
  id?: string
  customerId: string
  name: string
  type: DeviceType
  version?: string
  pairingCode?: string
  kioskconfig?: KioskConfig
  peripheralConfig?: PeripheralConfig
  openingTimeSlots: TimeSlot[]
}

export interface KioskConfig {
  version: string
  url: string
  whitelist: string
  whitelistUrl: string
  homeTime: number
  idleTime: number
  backgroundColor: string
}

export interface PeripheralConfig {
  paymentTerminalType: PaymentTerminalType
  stripeSdkConfig?: StripeSDKConfig
  stripeReaderId?: string
  verifoneReaderId?: string
}

export class StripeSDKConfig {
  stripeLocationId?: string
  stripeSDKConnectionMethod?: StripeSDKConnectionMethod
}

export interface TimeSlot {
  type: string;
  weekDays: string[];
  allDay: boolean;
  startTime: string;
  endTime: string;
  start: string;
  end: string;
}

enum DeviceType {
  SCR_ELECTRON,
  SCR_RASPBERRY_PI,
  SCR_X86,
  SCR_LG,
  SCR_TIZEN,
  SCR_SCREENSAVER,
  KIOSK,
  IMMOTOUCH
}

export enum PaymentTerminalType {
  NONE = "NONE",
  VERIFONE = "VERIFONE",
  STRIPE_CLOUD = "STRIPE_CLOUD",
  STRIPE_SDK = "STRIPE_SDK"
}

export enum StripeSDKConnectionMethod {
  USB = "USB",
  BLUETOOTH = "BLUETOOTH",
  TAPTOPAY = "TAPTOPAY"
}

export enum KioskApplication {
  SCREENLAB,
  HUMETIPS,
  HUMECHARITY,
  HUMEORDER,
  HUMESERVICES,
  CUSTOM
}
