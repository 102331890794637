import {Injectable} from '@angular/core';
import {Settings} from "../../interfaces/settings";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Observable} from "rxjs";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  retrieveSettings(): Observable<Settings> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.get(`${url}/settings`)
      .pipe(map((res: InvocationResult) => {
          return res.data;
        })
      );
  }
}
