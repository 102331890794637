import {Injectable} from '@angular/core';
import {Observable, retry} from "rxjs";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {HttpClient} from "@angular/common/http";
import {Tip} from "../../interfaces/tip";
import {CreateTipDto} from "../../interfaces/dto/create-tip-dto";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";
import {PatchTipDto} from "../../interfaces/dto/patch-tip-dto";

@Injectable({
  providedIn: 'root'
})
export class TipService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  create(tip: CreateTipDto): Observable<Tip> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.post<InvocationResult>(url + '/tips', tip)
      .pipe(
        map((result: InvocationResult) => result.data),
        retry({count: 5, delay: 2000})
      )
  }

  patch(tipId: string, patchedTip: PatchTipDto): Observable<Tip> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.patch<InvocationResult>(url + '/tips/' + tipId, patchedTip)
      .pipe(
        map((result: InvocationResult) => result.data),
        retry({count: 5, delay: 2000})
      )
  }

}
