<div class="language-selector" (click)="languageClicked(null)">
  <div class="language-selector_list">
    <div class="language-selector_list_language" *ngFor="let language of languages" (click)="languageClicked(language)">
      <img class="language-selector_list_language_flag"
           [src]="'https://unpkg.com/language-icons/icons/' + language.code + '.svg'"/>
      <div class="language-selector_list_language_name">
        {{ language.nativeName}}
      </div>
    </div>
  </div>
</div>
