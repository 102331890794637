import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ApplicationHelper} from "../../shared/helpers/application/application.helper";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {InvocationResult} from "../../shared/interfaces/invocation-result";
import {Team} from "../../interfaces/team";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
    // do nothing
  }

  getTeams(): Observable<Team[]> {
    const url = this.appHelper.getApiUrlByType('HTI')

    const queryParams = new HttpParams()
      .set('perPage', 1000)
      .set('page', 0)

    return this.httpClient
      .get(url + '/teams', {params: queryParams})
      .pipe(map((result: InvocationResult) => {
          return result.data
        })
      );
  }
}
