import {Component, OnDestroy, OnInit} from '@angular/core';
import {DeviceService} from "../../services/devices/device.service";
import {Subscription} from "rxjs";
import {KeycloakService} from "../../shared/services/keycloak.service";
import {LocalstorageHelper} from "../../shared/helpers/localstorage/localstorage.helper";
import {UtilsHelper} from "../../shared/helpers/utils/utils.helper";
import {Router} from "@angular/router";

@Component({
  selector: 'app-pairing',
  templateUrl: './pairing.component.html',
  styleUrls: ['./pairing.component.scss']
})
export class PairingComponent implements OnInit, OnDestroy {

  TIME_TO_PAIR: number = 90

  subscriptions: Subscription = new Subscription()

  intervalPairing: NodeJS.Timeout
  intervalTimer: NodeJS.Timeout

  instance = 'production'
  canChangeInstance: boolean = false

  pairingCode: string = ''
  timeLeft: number = this.TIME_TO_PAIR
  minutes: number = 0
  seconds: number = 0

  deviceId: string

  constructor(
    private router: Router,
    private localstorageHelper: LocalstorageHelper,
    private keycloakService: KeycloakService,
    private utilsHelper: UtilsHelper,
    private deviceService: DeviceService,
  ) {
  }

  ngOnInit(): void {
    this.generatePairingCode()
    this.processTimeLeft()
    this.initTimer()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
    clearInterval(this.intervalPairing)
    clearInterval(this.intervalTimer)
  }

  initTimer(): void {
    this.timeLeft = this.TIME_TO_PAIR
    this.localstorageHelper.setLocalInstance(this.instance)

    this.intervalTimer = setInterval(function () {
      this.timeLeft -= 1;
      if (this.timeLeft == 0) {
        this.timeLeft = this.TIME_TO_PAIR
        this.generatePairingCode()
      }
      this.processTimeLeft()
    }.bind(this), 1000)

    this.pairingDevice()
  }

  processTimeLeft(): void {
    this.minutes = Math.round((this.timeLeft - (this.timeLeft % 60)) / 60)
    this.seconds = this.timeLeft % 60
  }

  generatePairingCode(): void {
    this.pairingCode = '';
    let chars = 'ABCDEFGHJKMNPQRSTUVWXYZ123456789';

    for (let i = 0; i < 6; i++) {
      this.pairingCode += chars.charAt(Math.floor(Math.random() * chars.length));
    }
  }

  pairingDevice() {
    this.intervalPairing = setInterval(function () {
      this.subscriptions.add(this.deviceService.pairing(this.instance, this.pairingCode).subscribe(res => {

        if (res.paired) {
          clearInterval(this.intervalTimer)
          clearInterval(this.intervalPairing)

          this.localstorageHelper.setLocalStorageDeviceId(res.id)
          this.status(res.id)
        }
      }))
    }.bind(this), 5000)
  }

  instanceChanged() {
    clearInterval(this.intervalPairing)
    this.pairingDevice()
  }

  status(id: string) {
    this.deviceId = id

    this.subscriptions.add(this.deviceService.status(this.deviceId).subscribe(res => {
      let cid = this.utilsHelper.getParamValueQueryString(res.kioskconfig.url, 'cid')
      this.localstorageHelper.setLocalStorageCid(cid)

      this.keycloakService.getAccessToken(true).subscribe(x => {
        this.router.navigate(['/home']);
      })
    }))
  }
}
