import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Settings} from "../../interfaces/settings";
import {Theme} from "../../interfaces/theme";
import {Collaborator} from "../../interfaces/collaborator";
import {Team} from "../../interfaces/team";
import {Action} from "../../interfaces/action.enum";
import {CollaboratorType} from 'src/app/interfaces/collaborator-type.enum';
import {Language} from "../../interfaces/language";
import {Subject} from "rxjs";

@Component({
  selector: 'app-humetips-selector',
  templateUrl: './humetips-selector.component.html',
  styleUrls: ['./humetips-selector.component.scss']
})
export class HumetipsSelectorComponent implements OnInit {

  NB_OF_COLLABORATORS_TO_SHOW: number = 8
  NB_OF_TEAMS_TO_SHOW: number = 4

  @Output() collaboratorTypeChanged: EventEmitter<CollaboratorType> = new EventEmitter<CollaboratorType>()
  @Output() collaboratorClicked: EventEmitter<Collaborator[]> = new EventEmitter<Collaborator[]>()
  @Output() teamClicked: EventEmitter<Team> = new EventEmitter<Team>()

  @Input() selectedLanguage: Language
  @Input() settings: Settings
  @Input() theme: Theme
  @Input() resetComponent: Subject<boolean>;

  private _collaborators: Collaborator[] = []
  @Input() set collaborators(value: Collaborator[]) {
    this._collaborators = value
    this.setDisplayedCollaboratorList()
  }

  get collaborators() {
    return this._collaborators
  }

  private _teams: Team[] = []
  @Input() set teams(value: Team[]) {
    this._teams = value
    this.setDisplayedTeamList()
  }

  get teams() {
    return this._teams
  }

  Action: typeof Action = Action;

  CollaboratorType: typeof CollaboratorType = CollaboratorType;
  collaboratorType: CollaboratorType = CollaboratorType.NONE

  displayedCollaborators: Collaborator[] = []
  collaboratorListPage: number = 0
  minCollaboratorPageReach: boolean = true
  maxCollaboratorPageReach: boolean = false
  selectedCollaborators: Collaborator[] = []

  displayedTeams: Team[] = []
  teamListPage: number = 0
  minTeamPageReach: boolean = true
  maxTeamPageReach: boolean = false
  selectedTeam: Team = null

  constructor() {
    // do nothing
  }

  ngOnInit(): void {
    this.resetComponent.subscribe(_ => this.reset())
  }

  teamHasBeenClicked(team: Team) {
    this.selectedTeam = team
    this.teamClicked.emit(team)
  }

  changeCollaboratorType(type: CollaboratorType) {
    this.collaboratorType = type;
    this.collaboratorTypeChanged.emit(type)
  }

  collaboratorHasBeenClicked(collaborator: Collaborator) {
    const index = this.selectedCollaborators.findIndex(c => c.id === collaborator.id);

    if (index === -1) {
      this.selectedCollaborators.push(collaborator);
    } else {
      this.selectedCollaborators.splice(index, 1);
    }
    this.collaboratorClicked.emit(this.selectedCollaborators)
  }

  changeCollaboratorPageValue(value: Action) {
    switch (value) {
      case Action.PLUS:
        if (!this.maxCollaboratorPageReach) {
          this.collaboratorListPage++
        }
        break
      case Action.MINUS:
        if (this.collaboratorListPage > 0) {
          this.collaboratorListPage--
        }
        break
      default:
        break
    }

    this.setDisplayedCollaboratorList()
  }

  changeTeamPageValue(value: Action) {
    switch (value) {
      case Action.PLUS:
        if (!this.maxTeamPageReach) {
          this.teamListPage++
        }
        break
      case Action.MINUS:
        if (this.teamListPage > 0) {
          this.teamListPage--
        }
        break
      default:
        break
    }

    this.setDisplayedTeamList()
  }

  setDisplayedTeamList() {
    let page = this.teamListPage

    let startIndex = page * this.NB_OF_TEAMS_TO_SHOW
    let endIndex = (page + 1) * this.NB_OF_TEAMS_TO_SHOW
    this.displayedTeams = this.teams.slice(startIndex, endIndex)

    let nextPageStartIndex = (page + 1) * this.NB_OF_TEAMS_TO_SHOW
    let nextPageEndIndex = (page + 2) * this.NB_OF_TEAMS_TO_SHOW
    this.maxTeamPageReach = this.teams.slice(nextPageStartIndex, nextPageEndIndex).length == 0

    this.minTeamPageReach = this.teamListPage == 0
  }

  isCollaboratorSelected(collaboratorId: string): boolean {
    return this.selectedCollaborators.some(c => c.id === collaboratorId);
  }

  setDisplayedCollaboratorList() {
    let page = this.collaboratorListPage

    let startIndex = page * this.NB_OF_COLLABORATORS_TO_SHOW
    let endIndex = (page + 1) * this.NB_OF_COLLABORATORS_TO_SHOW
    this.displayedCollaborators = this.collaborators.slice(startIndex, endIndex)

    let nextPageStartIndex = (page + 1) * this.NB_OF_COLLABORATORS_TO_SHOW
    let nextPageEndIndex = (page + 2) * this.NB_OF_COLLABORATORS_TO_SHOW
    this.maxCollaboratorPageReach = this.collaborators.slice(nextPageStartIndex, nextPageEndIndex).length == 0

    this.minCollaboratorPageReach = this.collaboratorListPage == 0
  }

  reset() {
    this.collaboratorType = CollaboratorType.NONE
    this.selectedCollaborators = []
    this.selectedTeam = null

    this.teamClicked.emit(this.selectedTeam)
    this.collaboratorClicked.emit(this.selectedCollaborators)
  }
}
