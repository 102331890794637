import {Component, ElementRef, NgZone, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Collaborator, CollaboratorStatus, StripeKycStatus} from '../../interfaces/collaborator';
import {
  forkJoin,
  interval,
  lastValueFrom,
  mergeMap,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  throwError,
  zip
} from 'rxjs';
import {StripeApiService} from '../../services/stripe-api/stripe-api.service';
import {
  ConnectionStatusEvent,
  DisconnectEvent,
  loadStripeTerminal,
  StripeTerminal,
  Terminal,
} from '@stripe/terminal-js';
import {environment} from "../../../environments/environment";
import {CollaboratorService} from "../../services/collaborators/collaborator.service";
import {TipService} from "../../services/tips/tip.service";
import {MatDialog} from "@angular/material/dialog";
import {StripePaymentModalComponent} from "../../widgets/stripe-payment-modal/stripe-payment-modal.component";
import {Theme} from "../../interfaces/theme";
import {SettingsService} from "../../services/settings/settings.service";
import {ThemeService} from "../../services/themes/theme.service";
import {MediaService} from "../../services/medias/media.service";
import {Media} from "../../shared/interfaces/media";
import {AmountService} from "../../services/amounts/amount.service";
import {Amount} from "../../interfaces/amount";
import {DecimalPipe} from "@angular/common";
import {Settings} from "../../interfaces/settings";
import {map, switchMap, takeUntil, tap} from "rxjs/operators";
import {Reason} from "../../interfaces/reason";
import {ReasonService} from "../../services/reasons/reason.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CustomerDetailsComponent, PaymentDocuments} from "../../widgets/customer-details/customer-details.component";
import {Platform, PlatformHelper, PlatformType} from "../../shared/helpers/platform/platform.helper";
import {LocalstorageHelper} from "../../shared/helpers/localstorage/localstorage.helper";
import {ThemeHelper} from "../../shared/helpers/themes/theme.helper";
import {KioskApiService} from "../../services/kiosk/kiosk-api.service";
import {TranslateService} from "@ngx-translate/core";
import {PaymentStepStatus} from "../../interfaces/payment-step.status";
import {CreatePaymentIntentDto} from "../../interfaces/dto/create-payment-intent-dto";
import {Debit} from "../../interfaces/kiosk-api/debit";
import {DeviceService} from "../../services/devices/device.service";
import {NgxSpinnerService} from "ngx-spinner";
import {StripeReaderService} from "../../services/stripe-reader/stripe-reader.service";
import {ErrorType} from "../../interfaces/error.type";
import {UtilsHelper} from "../../shared/helpers/utils/utils.helper";
import {Team} from "../../interfaces/team";
import {TeamService} from "../../services/teams/team.service";
import {PaymentStatus} from 'src/app/interfaces/payment-status';
import {DynamicTranslationPipe} from "../../shared/pipes/dynamic-translation/dynamic-translation.pipe";
import {Language} from "../../interfaces/language";
import {LanguageService} from "../../services/languages/language.service";
import {StripeModalResult} from "../../interfaces/stripe-modal.data";
import {CollaboratorType} from 'src/app/interfaces/collaborator-type.enum';
import {AppStep} from 'src/app/interfaces/app-step.enum';
import {Action} from 'src/app/interfaces/action.enum';
import {Device, PaymentTerminalType, TimeSlot} from "../../interfaces/device";
import {Tip} from "../../interfaces/tip";
import {PatchTipDto} from "../../interfaces/dto/patch-tip-dto";
import {DocumentStatus, PaymentPoint, PaymentType} from "../../interfaces/payment";
import {CreateTipDto} from 'src/app/interfaces/dto/create-tip-dto';
import {AndroidMessage, AndroidMessageSource, AndroidMessageType} from "../../interfaces/android-message";

import {StripeService} from 'ngx-stripe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

  //#region Constants

  readonly DEFAULT_TIME_IN_MS_BEFORE_RESET_AFTER_PAYMENT: number = 5000
  readonly TIME_BEFORE_ADMIN_COUNT_RESET_IN_MS: number = 5000
  readonly TIME_BEFORE_SHOWING_VERIFONE_CANCEL_HELPER_IN_MS: number = 15000
  readonly TIME_BEFORE_RESET_AFTER_FAILED_PAYMENT_IN_MS: number = 15000
  readonly TIME_BEFORE_STRIPE_PAYMENT_TIMEOUT_IN_MS: number = 20000
  readonly TAP_NEEDED_TO_ACCESS_ADMIN: number = 1
  readonly TIME_BEFORE_RESET_IN_SEC: number = 120

  //#endregion

  //#region Properties

  subscriptions: Subscription = new Subscription()
  destroy$ = new Subject<void>()

  @ViewChild('reasonInput') reasonInput: ElementRef

  AppStep: typeof AppStep = AppStep;
  PaymentStepStatus: typeof PaymentStepStatus = PaymentStepStatus;
  PaymentDocuments: typeof PaymentDocuments = PaymentDocuments;
  Platform: typeof Platform = Platform;

  Action: typeof Action = Action;

  simulatePayment: boolean = false

  currentAppStep: AppStep = AppStep.HOME

  tipValue: number = 0.5
  customTipValue = 0
  customTipValueStr: string = "0"
  amountKeyboardVisible = false


  settings: Settings
  theme: Theme
  logo: Media = null
  screensaverMedias: Media[] = []
  retrieveSettingsInterval: NodeJS.Timeout

  currentScreensaver: Media
  screensaverSubscription: Subscription
  screensaverTimer: NodeJS.Timeout
  timeLeftBeforeReset: number = 120

  amounts: Amount[] = []
  startingAmount: number = 0.5
  incrementalAmount: number = 0.5
  amountsSliceNbr: number = 0
  showAllPreselectedAmount: boolean = false

  collaborators: Collaborator[] = []
  teams: Team[] = []
  collaboratorType: CollaboratorType = CollaboratorType.NONE

  selectedCollaborators: Collaborator[] = []

  selectedTeam: Team = null
  resetHumetipsSelector: Subject<boolean> = new Subject();

  isVerifonePaymentType: boolean = false
  paymentStatus: PaymentStepStatus = PaymentStepStatus.NONE
  verifoneCancelPopupVisible = false
  verifoneCancelPopupInterval: NodeJS.Timeout
  timeBeforeResetAfterFailedPaymentPopupInterval: NodeJS.Timeout
  failedTipAlreadyRegistered: boolean = false

  stripeTerminal: StripeTerminal | null
  terminal: Terminal
  appPaymentIntentId: string = null
  stripePaymentIntent: any = null
  debit: Debit = null
  stripeCardHolderName: string = ""

  currentTip: Tip = null
  askForCerfa: boolean = false
  askForTicket: boolean = false

  reasons: Reason[] = []
  selectedReason: Reason
  recipientLabel: string = ""
  customReasonForm: FormGroup = this.formBuilder.group({
    reason: [""]
  })

  platform: Platform
  appType: string = ""
  showAppType: boolean = environment.tweaks.showAppType
  showApplicationKeyboard: boolean = false

  stripeTerminalConnected: boolean = false
  errorMode: boolean = false
  errorValue: ErrorType = ErrorType.DEFAULT
  paymentTimeout: NodeJS.Timeout

  showAdmin: boolean = false
  intervalBeforeAdminCountReset: NodeJS.Timeout
  adminTapCount: number = 0

  languageSelectorVisible: boolean = false
  selectedLanguage: Language = {code: "fr", name: "French", nativeName: "Français"}
  languageList: Language[]

  openingHours: any[] = []

  device: Device

  androidInterface = (window as any).AndroidInterface
  clearCustomerSiteCache: boolean = false

  //#endregion

  constructor(
    private stripeApiService: StripeApiService,
    private stripeService: StripeService,
    private collaboratorService: CollaboratorService,
    private teamService: TeamService,
    private tipService: TipService,
    private settingsService: SettingsService,
    private mediaService: MediaService,
    private themeService: ThemeService,
    private amountService: AmountService,
    private reasonService: ReasonService,
    private languageService: LanguageService,
    private stripeReaderService: StripeReaderService,
    private translateService: TranslateService,
    private kioskApiService: KioskApiService,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceService,
    private platformHelper: PlatformHelper,
    private localStorageHelper: LocalstorageHelper,
    private utilsHelper: UtilsHelper,
    private themeHelper: ThemeHelper,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private numberService: DecimalPipe,
    private translationService: DynamicTranslationPipe,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    // do nothing
  }

  //#region Component Lifecycle

  ngOnInit(): void {
    this.platform = this.platformHelper.getPlatform()
    this.showApplicationKeyboard = this.platformHelper.platformShouldShowApplicationKeyboard()

    this.platformExecution()

    this.retrieveAllSettings(true)

    this.retrieveSettingsInterval = setInterval(() => {
      if (this.paymentStatus == PaymentStepStatus.NONE)
        this.retrieveAllSettings(false)
    }, 300000);
  }

  ngOnDestroy() {
    (window as any).receiveMessageFromAndroidApp = null
    this.subscriptions.unsubscribe()
    this.destroy$.next();
    this.destroy$.complete();
    clearInterval(this.retrieveSettingsInterval)
    clearInterval(this.intervalBeforeAdminCountReset)
    clearInterval(this.verifoneCancelPopupInterval)
    clearInterval(this.timeBeforeResetAfterFailedPaymentPopupInterval)
  }

  //#endregion

  //#region Settings and startup related methods

  retrieveAllSettings(firstLaunch: boolean = false) {
    let deviceId = this.localStorageHelper.getLocalStorageDeviceId()

    this.subscriptions.add(
      this.settingsService.retrieveSettings().pipe(
        mergeMap(settings => zip(
          of(settings),
          this.themeService.getThemeByThemeId(settings.enabledThemeId),
          settings.enablePaymentReasons ? this.reasonService.getReasons().pipe(
            map((curReasons) => {
              return curReasons
                .sort((a, b) => (a.order < b.order ? -1 : 1))
                .filter(reason => reason.enabled == true)
            })
          ) : of([]),
          settings.enableUniqueRecipient ? of([]) : this.collaboratorService.getCollaborators(null, CollaboratorStatus.READY, StripeKycStatus.COMPLETED, settings.enableCollaboratorClockIn),
          settings.enableUniqueRecipient ? of([]) : this.teamService.getTeams(),
          settings.enablePredefinedAmounts ? this.amountService.getAmounts() : of([]),
          this.languageService.getLanguages(),
          this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE) ? this.deviceService.status(deviceId) : of(null)
        ))
      ).subscribe((
        [
          settings,
          theme,
          reasons,
          collaborators,
          teams,
          amounts,
          languages,
          device
        ]) => {
        this.settings = settings
        this.isVerifonePaymentType = settings.enabledPaymentTypes.includes(PaymentType.VERIPHONE_PT)

        this.changeTheme(theme)

        this.reasons = reasons

        this.collaborators = collaborators

        const collaboratorIds = collaborators.map(collaborator => collaborator.id)
        this.teams = teams.filter((team: Team) => {
          const intersection = team.collaboratorIds.filter(collaboratorId => collaboratorIds.includes(collaboratorId))
          return team.enabled && intersection.length > 0
        })

        const allTeamIndex = this.teams.findIndex(team => team.id.includes("all-collaborators"));
        if (allTeamIndex !== -1) {
          const allTeam = this.teams[allTeamIndex];
          this.teams.splice(allTeamIndex, 1);
          this.teams.unshift(allTeam);
        }

        this.amounts = amounts.filter(amount => amount.enabled).sort((a, b) => (a.value < b.value ? -1 : 1))
        this.amountsSliceNbr = this.amounts.length > 3 ? (this.amounts.length == 4 ? 4 : 3) : 4

        this.startingAmount = settings.startingAmount
        this.incrementalAmount = settings.incrementalAmount

        this.languageList = languages.filter(l => this.settings.enabledLanguages.includes(l.code))

        this.device = device
        if (device?.kioskconfig) {
          let cid = this.utilsHelper.getParamValueQueryString(device.kioskconfig.url, 'cid')
          this.localStorageHelper.setLocalStorageCid(cid)
          this.openingHours = device.openingTimeSlots
        }

        if (this.platformHelper.isPlatform(PlatformType.WEB_APP)) {
          let stripe_pk = settings.enableUniqueRecipient ? settings.stripeAccountPublicKey : environment.stripe.publishable_key
          if (!stripe_pk && !settings.stripeAccountSecretKeyValid) {
            this.setErrorScreen(ErrorType.DEFAULT)
            return
          }
          this.stripeService.setKey(stripe_pk)
        }

        if (
          this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE) &&
          this.platformHelper.getPlatform() != Platform.SIMULATED_WEB_KIOSK &&
          this.platformHelper.getPlatform() != Platform.ANDROID_WEB_KIOSK &&
          settings.enabledPaymentTypes.includes(PaymentType.STRIPE_PT)
        ) {
          if (settings.enableUniqueRecipient && !settings.stripeAccountSecretKeyValid) {
            this.setErrorScreen(ErrorType.DEFAULT)
            return
          }

          this.checkIfDeviceHasStripeReader(deviceId, firstLaunch)
          return
        }

        this.periodicPlatformExecution()
        if (firstLaunch) this.reset()
      })
    )
  }

  getHostname() {
    return window.location.hostname;
  }

  platformExecution() {
    switch (this.platform) {
      case Platform.TIZEN:
        if (!this.getHostname().includes("localhost")) {
          this.addTizenScripts()
        }
        break
      case Platform.ANDROID_WEB_KIOSK:
        this.injectAndroidScript();
        break
    }
  }

  periodicPlatformExecution() {
    switch (this.platform) {
      case Platform.TIZEN: {
        this.appType = `KIOSK TIZEN`
        this.initTerminal()
        this.checkOpenStatus(this.openingHours);
        break
      }
      case Platform.WEB_KIOSK: {
        this.appType = `KIOSK WEB - SANS SIMULATION`
        this.initTerminal()
        break
      }
      case Platform.SIMULATED_WEB_KIOSK: {
        this.appType = `KIOSK WEB - SIMULATION`
        if (this.settings.enableSimulationApp) {
          this.simulatePayment = true
          this.initTerminal()
        } else {
          this.setErrorScreen(ErrorType.ASK_FOR_SIMULATION_UNAUTHORIZED)
        }
        break
      }
      case Platform.WEB: {
        this.appType = 'WEB'
        if (!this.settings.enabledPaymentTypes.includes(PaymentType.STRIPE_ONLINE)) {
          this.setErrorScreen(ErrorType.STRIPE_ONLINE_PAYMENT_TYPE_NOT_ENABLED)
        }
        break
      }
      case Platform.ANDROID_WEB_KIOSK: {
        if (!this.stripeTerminalConnected) {
          this.setErrorScreen(ErrorType.STRIPE_PT_NOT_CONNECTED)
        }
        if (
          this.settings.enabledPaymentTypes.includes(PaymentType.STRIPE_PT) &&
          this.device.peripheralConfig.paymentTerminalType == PaymentTerminalType.STRIPE_SDK
        ) {
          this.retrieveStripeReaderToken()
        }
        break
      }
    }
  }

  retrieveStripeReaderToken() {
    this.subscriptions.add(this.stripeApiService.getStripeAuthToken().subscribe(token => {
      let message: AndroidMessage = {
        source: AndroidMessageSource.WEB_APP,
        type: AndroidMessageType.STRIPE_INFORMATIONS,
        data: {
          stripeInfos: {
            token: token,
            locationId: this.device.peripheralConfig.stripeSdkConfig.stripeLocationId
          }
        }
      }
      this.androidInterface.initStripeToken(JSON.stringify(message));
    }))
  }

  //#endregion

  //#region View/Component interactions related methods

  changeTheme(theme: Theme) {
    this.theme = theme
    this.localStorageHelper.setLocalStorageTheme(theme)

    this.retrieveLogoMedia()
    if (this.theme.useIdleScreensaver) {
      this.retrieveScreensaverMedias()
    }

    this.themeHelper.loadTheme(this.elementRef.nativeElement)
  }

  setErrorScreen(errorType: ErrorType) {
    this.errorMode = true;
    this.errorValue = errorType;
  }

  changeCollaboratorType(type: CollaboratorType) {
    this.collaboratorType = type;
  }

  collaboratorHasBeenClicked(collaborators: Collaborator[]) {
    this.selectedCollaborators = collaborators
  }

  teamHasBeenClicked(team: Team) {
    this.selectedTeam = team
  }

  errorScreenClicked() {
    this.showAdmin = true
  }

  getDocumentType(): PaymentDocuments {
    if (this.askForTicket && this.askForCerfa) {
      return PaymentDocuments.CERFA_AND_TICKET
    } else if (this.askForCerfa) {
      return PaymentDocuments.CERFA
    } else if (this.askForTicket) {
      return PaymentDocuments.TICKET
    } else {
      return PaymentDocuments.NONE
    }
  }

  paymentDocumentChanged(paymentDocuments: PaymentDocuments, event: any): void {
    if (paymentDocuments == PaymentDocuments.CERFA) {
      this.askForCerfa = event.target.checked
    } else if (paymentDocuments == PaymentDocuments.TICKET) {
      this.askForTicket = event.target.checked
    }
  }

  reasonClicked(reason: Reason) {
    if (reason != null) {
      this.selectedReason = reason
    }
    this.updateRecipientLabel()
  }

  customReasonChanged(): void {
    this.reasonClicked(null)

    this.selectedReason = {
      name: this.customReasonForm.get('reason').value,
    }

    this.updateRecipientLabel()
  }

  updateRecipientLabel() {
    if (this.settings.enableUniqueRecipient) {
      this.recipientLabel = this.translationService.transform(this.settings.recipientLabel, this.selectedLanguage.code)
        .replace('{amount}', this.numberService.transform(this.tipValue, "1.2-2") + "€")
        .replace('{reason}', this.translationService.transform(this.selectedReason?.name, this.selectedLanguage.code))
    }
  }

  hideReasonKeyboard(): void {
    this.reasonInput.nativeElement.blur()
  }

  getValidateButtonStatus(): boolean {
    if (this.settings.enableUniqueRecipient) return true
    else {
      if (this.collaboratorType == CollaboratorType.TEAM) return this.selectedTeam != null
      else if (this.collaboratorType == CollaboratorType.EMPLOYEE) return this.selectedCollaborators.length > 0
      else return false
    }
  }

  adminAccessButtonClicked(): void {
    if (this.adminTapCount == 0) {
      this.intervalBeforeAdminCountReset = setInterval(() => {
        this.adminTapCount = 0
      }, this.TIME_BEFORE_ADMIN_COUNT_RESET_IN_MS)
    }

    this.adminTapCount += 1

    if (
      this.adminTapCount >= this.TAP_NEEDED_TO_ACCESS_ADMIN && this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      this.showAdmin = true
      clearInterval(this.intervalBeforeAdminCountReset)
    }
  }

  changeAdminMenuState(visible: boolean): void {
    this.showAdmin = visible
    this.adminTapCount = 0
  }

  userActionOnScreen() {
    this.timeLeftBeforeReset = this.TIME_BEFORE_RESET_IN_SEC
  }

  //#endregion

  //#region Idle/Reset related methods

  reset() {
    this.spinner.hide()

    this.tipValue = this.startingAmount

    this.resetHumetipsSelector.next(true);

    clearInterval(this.verifoneCancelPopupInterval)
    clearInterval(this.timeBeforeResetAfterFailedPaymentPopupInterval)

    this.handleScreens("backward")

    this.paymentStatus = PaymentStepStatus.NONE
    this.verifoneCancelPopupVisible = false
    this.appPaymentIntentId = null
    this.stripePaymentIntent = null
    this.debit = null
    this.stripeCardHolderName = ""
    this.failedTipAlreadyRegistered = false
    this.currentTip = null

    this.askForCerfa = false
    this.askForTicket = false
    this.setDefaultSelectedLanguage()

    if (this.settings.enablePaymentReasons && this.reasons.length > 0) {
      this.customReasonForm.controls['reason'].setValue("")
      this.reasonClicked(this.reasons[0])
    }

    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      this.loadScript("window.chrome.webview.postMessage(\"allowKioskIdle\")")
    }

    this.updateRecipientLabel()

    if (this.theme.enableAppSelection && this.clearCustomerSiteCache) {
      let message: AndroidMessage = {
        source: AndroidMessageSource.WEB_APP,
        type: AndroidMessageType.CLEAR_CACHE,
        data: {
          customerUrl: this.theme.appSelectionConfig.customerAppUrl
        }
      }
      this.androidInterface.clearCustomerUrlCache(JSON.stringify(message));
    }
  }

  startResetTimer() {
    if (this.platformHelper.getPlatform() == Platform.ANDROID_WEB_KIOSK) return

    if (this.screensaverTimer) {
      clearInterval(this.screensaverTimer)
    }

    this.timeLeftBeforeReset = this.TIME_BEFORE_RESET_IN_SEC

    this.screensaverTimer = setInterval(() => {
      if (this.paymentStatus == PaymentStepStatus.NONE) {
        this.timeLeftBeforeReset--
        if (this.timeLeftBeforeReset == 0) {
          this.reset()
        }
        return
      } else this.timeLeftBeforeReset = this.TIME_BEFORE_RESET_IN_SEC
    }, 1000)
  }

  startScreensaver() {
    this.screensaverSubscription?.unsubscribe();

    if (this.screensaverMedias.length > 0) {
      this.screensaverSubscription = interval(10000)
        .pipe(
          startWith(0),
          map(i => this.screensaverMedias[i % this.screensaverMedias.length])
        )
        .subscribe(media => {
          this.currentScreensaver = media
        });
    }
  }

  leaveScreensaver() {
    this.screensaverSubscription?.unsubscribe()
    this.handleScreens("forward")
  }

  handleScreens(direction: "backward" | "forward") {
    const actions = {
      [AppStep.SCREENSAVER]: () => {
        if (direction === "forward") {
          this.currentAppStep = this.theme.useIdleScreensaver ? AppStep.CUSTOM_URL :
            this.settings.enableFeedbacks ? AppStep.FEEDBACK : AppStep.HOME;
          this.startResetTimer()
        }
      },
      [AppStep.FEEDBACK]: () => {
        if (direction === "backward" && this.theme.useIdleScreensaver) {
          this.resetAppStep()
        }
        if (direction === "forward") {
          this.currentAppStep = this.theme.enableAppSelection ? AppStep.CUSTOM_URL : AppStep.HOME;
          this.startResetTimer()
        }
      },
      [AppStep.CUSTOM_URL]: () => {
        if (direction === "backward") {
          this.resetAppStep()
        }
        if (direction === "forward") {
          this.currentAppStep = AppStep.HOME;
          this.startResetTimer()
        }
      },
      [AppStep.HOME]: () => {
        if (direction === "backward") {
          this.resetAppStep()
        }
      },
    };

    actions[this.currentAppStep]?.();
  }

  resetAppStep() {
    if (this.theme.useIdleScreensaver) {
      this.currentAppStep = AppStep.SCREENSAVER;
      this.startScreensaver()
    } else if (this.settings.enableFeedbacks) {
      this.currentAppStep = AppStep.FEEDBACK;
    } else if (this.theme.enableAppSelection) {
      this.currentAppStep = AppStep.CUSTOM_URL
    }
    clearInterval(this.screensaverTimer)
  }

  resetLocalstorage() {
    localStorage.clear()
    location.reload()

    if (this.platform == Platform.ANDROID_WEB_KIOSK && this.androidInterface) {
      let message: AndroidMessage = {
        source: AndroidMessageSource.WEB_APP,
        type: AndroidMessageType.RESET_PAIRING,
      }
      this.androidInterface.restartPairingDevice(JSON.stringify(message));
    }
  }

  //#endregion

  //#region Android related methods

  injectAndroidScript() {
    const script = this.renderer.createElement('script');
    const text = this.renderer.createText(`
        function callableFunction(message) {
            console.log("callableFunction was called with:", message.toString());
            if (window.receiveMessageFromAndroidApp) {
                window.receiveMessageFromAndroidApp(message);
            }
        }
    `);

    this.renderer.appendChild(script, text);
    this.renderer.appendChild(this.elementRef.nativeElement, script);

    (window as any).receiveMessageFromAndroidApp = this.receiveMessageFromAndroidApp.bind(this);

    if (this.platform == Platform.ANDROID_WEB_KIOSK && this.androidInterface) {
      this.androidInterface.scriptReady();
    }
  }

  receiveMessageFromAndroidApp(message: string) {
    this.zone.run(() => {
      let androidMessage = Object.assign(new AndroidMessage(), JSON.parse(message)) as AndroidMessage;
      console.log("Message : ", message.toString());

      if (androidMessage.type == AndroidMessageType.READER_CONNECTION_STATUS) {
        this.stripeTerminalConnected = androidMessage.data.readerConnected
        let newTokenNeeded = androidMessage.data.newTokenNeeded

        if (this.stripeTerminalConnected == true) {
          this.errorMode = false
        } else {
          this.setErrorScreen(ErrorType.STRIPE_PT_NOT_CONNECTED)
        }

        if (newTokenNeeded) {
          this.retrieveStripeReaderToken()
        }
      }

      if (androidMessage.type == AndroidMessageType.RESET_APP) {
        if (this.paymentStatus == PaymentStepStatus.NONE) {
          this.reset()
        }
      }

      if (androidMessage.type == AndroidMessageType.PAYMENT_RESULT) {
        let paymentResult = androidMessage.data.paymentResult;
        this.stripePaymentIntent = paymentResult.stripePaymentIntent;

        if (paymentResult.success) {
          this.capturePayment();
        } else {
          this.paymentStatus = PaymentStepStatus.FAILURE;
          this.startTimerBeforeResetAfterFailedPayment();
        }
      }
    });
  }

  //#endregion

  //#region Tizen related methods

  addTizenScripts() {
    let tizenScriptSources = ["$B2BAPIS/b2bapis/b2bapis.js", "$WEBAPIS/webapis/webapis.js"]

    tizenScriptSources.forEach(script_src => {
      let script = document.createElement("script");
      script.src = script_src;
      script.type = "text/javascript";

      script.onerror = function () {
        console.error("Script not found: " + script_src);
      };

      document.head.appendChild(script);
    })
  }

  checkOpenStatus(timeslots: any[]) {
    if (!timeslots || timeslots.length == 0) {
      this.setScreenMuted(false)
      return
    }

    let now = new Date()
    let isOpen = false

    for (let i = 0; i < timeslots.length; i++) {
      let timeSlot = timeslots[i]
      if (this.isDateInTimeSlot(now, timeSlot)) {
        isOpen = true
        break
      }
    }

    if (isOpen) {
      this.setScreenMuted(false)
    } else {
      this.setScreenMuted(true)
    }
  }

  isDateInTimeSlot(date: Date, timeSlot: TimeSlot): boolean {
    if (timeSlot.type == 'WEEKLY') {
      const dayOfWeek = this.getDayOfWeekOfDate(date);
      if (timeSlot.weekDays.indexOf(dayOfWeek) >= 0) {
        if (timeSlot.allDay) {
          return true;
        } else {
          const startSplit = timeSlot.startTime.split(':');
          const startTime = new Date(date);
          startTime.setHours(Number(startSplit[0]), Number(startSplit[1]), Number(startSplit[2]));
          const endSplit = timeSlot.endTime.split(':');
          const endTime = new Date(date);
          endTime.setHours(Number(endSplit[0]), Number(endSplit[1]), Number(endSplit[2]));
          return date >= startTime && date < endTime;
        }
      } else {
        return false;
      }
    } else {
      const start = new Date(timeSlot.start);
      const end = new Date(timeSlot.end);
      if (timeSlot.allDay) {
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
      }
      return date >= start && date < end;
    }
  }

  getDayOfWeekOfDate(date: Date): string {
    switch (date.getDay()) {
      case 0:
        return 'SUNDAY';
      case 1:
        return 'MONDAY';
      case 2:
        return 'TUESDAY';
      case 3:
        return 'WEDNESDAY';
      case 4:
        return 'THURSDAY';
      case 5:
        return 'FRIDAY';
      case 6:
        return 'SATURDAY';
      default:
        throw new Error('Can\'t recover actual day of the week');
    }
  }

  setScreenMuted(muted: boolean) {
    let label = muted ? "ON" : "OFF"

    // @ts-ignore
    b2bapis.b2bcontrol.setPanelMute(label, (val) => {
      console.log("[setPanelMute] success : " + val);
    }, (error) => {
      console.log("[setPanelMute] code :" + error.code + " error name: " + error.name + " message " + error.message);
    });
  }

  //#endregion

  //#region Windows kiosk related methods

  loadScript(code: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.text = code;
    document.head.appendChild(script);
  }

  //#endregion

  //#region Language selection related methods

  showLanguageSelector() {
    this.languageSelectorVisible = true
  }

  languageSelectorClicked(language: Language) {
    this.languageSelectorVisible = false

    if (language != null) {
      this.selectedLanguage = language
      this.localStorageHelper.setLocalStorageLanguage(language)

      this.translateService.use(language.code)

      this.updateRecipientLabel()
    }
  }

  setDefaultSelectedLanguage() {
    let code = this.settings.defaultLanguage != null ? this.settings.defaultLanguage : 'fr';
    this.languageSelectorClicked(this.languageList.find(l => l.code == code))
  }

  //#endregion

  //#region Tip value selection related methods

  changeTipValue(action: Action) {
    if (this.paymentStatus == PaymentStepStatus.NONE) {
      switch (action) {
        case Action.MINUS:
          if ((this.tipValue - this.incrementalAmount) > 0) {
            this.tipValue -= this.incrementalAmount;
          } else {
            this.tipValue = 0.5
          }
          break;
        case Action.PLUS:
          if (this.tipValue < 5000) {
            this.tipValue += this.incrementalAmount;
          }
          break;
        default:
          break;
      }

      this.updateRecipientLabel()
    }
  }

  setPreselectedTipValue(value: number) {
    const SHOW_ALL_VALUES = -1;

    if (!value) {
      this.showAllPreselectedAmount = false
      return
    }
    if (value == SHOW_ALL_VALUES) {
      this.showAllPreselectedAmount = true
      return
    }

    this.showAllPreselectedAmount = false
    this.tipValue = value
    this.updateRecipientLabel()
  }

  showAmountKeyboard() {
    if (this.settings.enableUniqueAmount) return

    this.customTipValueStr = "0"
    this.customTipValue = this.recomposeAmountFromString()
    this.amountKeyboardVisible = true
  }

  hideAmountKeyboard() {
    if (this.customTipValue >= this.settings.startingAmount) {
      this.tipValue = this.customTipValue
    }

    this.amountKeyboardVisible = false
    this.updateRecipientLabel()
  }

  amountKeyboardKeyPressed(key: string) {
    switch (key) {
      case "validate":
        this.hideAmountKeyboard()
        break;
      case "erase":
        this.customTipValueStr = this.customTipValueStr.slice(0, -1)
        break;
      case ".":
        if (!this.customTipValueStr.includes(".")) {
          this.customTipValueStr += "."
        }
        break;
      default:
        const [integerPart, decimalPart] = this.customTipValueStr.split('.')
        if (decimalPart && decimalPart.length >= 2) {
          return
        }
        if (this.customTipValueStr === '0') {
          this.customTipValueStr = key;
        } else {
          this.customTipValueStr += key;
        }
        break;
    }

    this.customTipValue = this.recomposeAmountFromString()
  }

  recomposeAmountFromString(): number {
    let parsedAmount = parseFloat(this.customTipValueStr)

    if (isNaN(parsedAmount)) {
      this.customTipValueStr = "0"
      return 0
    } else if (parsedAmount > 5000) {
      this.customTipValueStr = "5000"
      return 5000
    }
    return parsedAmount
  }

  //#endregion

  //#region Payment related methods

  validate() {
    this.failedTipAlreadyRegistered = false

    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      clearInterval(this.timeBeforeResetAfterFailedPaymentPopupInterval)
      this.paymentStatus = PaymentStepStatus.WAITING
    }

    let paymentType = this.isVerifonePaymentType ? PaymentType.VERIPHONE_PT
      : this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE) ? PaymentType.STRIPE_PT : PaymentType.STRIPE_ONLINE

    let collaboratorsIds: string[] = []
    if (!this.settings.enableUniqueRecipient) {
      collaboratorsIds = this.collaboratorType != CollaboratorType.TEAM
        ? this.selectedCollaborators.map(collaborator => collaborator.id)
        : this.selectedTeam.collaboratorIds;
    }

    let tip: CreateTipDto = {
      demonstration: this.simulatePayment,
      valueInCents: this.tipValue * 100,
      teamId: this.collaboratorType == CollaboratorType.TEAM ? this.selectedTeam?.id : null,
      collaboratorIds: collaboratorsIds,
      reason: this.translationService.transform(this.selectedReason?.name),
      type: paymentType,
      point: this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE) ? PaymentPoint.KIOSK : PaymentPoint.WEB_APP,
      status: PaymentStatus.WAITING,
      cerfaStatus: this.askForCerfa ? DocumentStatus.WAITING : DocumentStatus.NONE,
      ticketStatus: this.askForTicket ? DocumentStatus.WAITING : DocumentStatus.NONE,
      comment: "",
      finalized: false,
    }

    this.subscriptions.add(this.tipService.create(tip).subscribe(result => {
      this.currentTip = result
      this.startPayment()
    }))
  }

  startPayment() {
    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      this.loadScript("window.chrome.webview.postMessage(\"forbidKioskIdle\")")

      if (this.isVerifonePaymentType) {
        this.verifoneCheckStarted()
      } else if (this.settings.enabledPaymentTypes.includes(PaymentType.STRIPE_PT)) {
        if (this.simulatePayment) {
          this.terminal.setSimulatorConfiguration({
            testCardNumber: environment.stripe.simulation_card
          });
        }

        let createPaymentIntentDto: CreatePaymentIntentDto = {
          price: this.tipValue.toString(),
          kioskPayment: true
        }

        this.subscriptions.add(
          this.stripeApiService.createPaymentIntent(createPaymentIntentDto).subscribe(paymentIntent => {
            this.appPaymentIntentId = paymentIntent.id

            if (this.platformHelper.getPlatform() == Platform.ANDROID_WEB_KIOSK) {
              this.androidInterface.receivePaymentRequest(JSON.stringify({
                clientSecret: paymentIntent.stripeClientSecret
              }))
              return
            }

            this.collectPayment(paymentIntent.stripeClientSecret)
            this.paymentTimeout = setTimeout(() => {
              this.cancelPayment()
            }, this.TIME_BEFORE_STRIPE_PAYMENT_TIMEOUT_IN_MS)
          })
        )
      } else {
        throwError(() => new Error('There is no payment type configured'))
      }
    } else {
      const dialogRef = this.dialog.open(StripePaymentModalComponent, {
        width: '100%',
        disableClose: true,
        data: {
          value: this.tipValue,
          isUniqueRecipient: this.settings.enableUniqueRecipient
        },
      });
      dialogRef.afterClosed().subscribe((stripeModalResult: StripeModalResult) => {
        this.appPaymentIntentId = stripeModalResult.htiPaymentIntent.id
        this.stripePaymentIntent = stripeModalResult.stripePaymentIntent
        this.stripeCardHolderName = stripeModalResult.cardHolderName

        this.registerTipAfterSuccess()
      });
    }
  }

  registerTipAfterSuccess() {
    clearInterval(this.verifoneCancelPopupInterval)

    let askedPaymentDocuments = this.getDocumentType();

    this.registerTip(true).pipe(
      switchMap(tip => {
        let showDialog = askedPaymentDocuments != PaymentDocuments.NONE

        if (showDialog) {
          return this.showDocumentsModal(askedPaymentDocuments, tip).pipe(
            tap(() => this.redirectOrResetAfterPayment(true))
          );
        } else {
          this.redirectOrResetAfterPayment(false)
          return of(null)
        }
      }),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  cancelAfterFailedPayment() {
    const registerTip$ = this.failedTipAlreadyRegistered ? of(null) : this.registerTip(false)

    registerTip$.pipe(
      tap(() => this.redirectOrResetAfterPayment(true)),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  retryAfterFailedPayment() {
    const registerTip$ = this.failedTipAlreadyRegistered ? of(null) : this.registerTip(false)

    registerTip$.pipe(
      tap(() => this.validate()),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  askForTicketAfterFailedPayment() {
    this.registerTip(false).pipe(
      tap(() => this.failedTipAlreadyRegistered = true),
      switchMap(tip => this.showDocumentsModal(PaymentDocuments.TICKET, tip)),
      takeUntil(this.destroy$)
    ).subscribe()
  }

  registerTip(success: boolean): Observable<Tip> {
    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      clearInterval(this.timeBeforeResetAfterFailedPaymentPopupInterval)
    }

    let paymentType = this.isVerifonePaymentType ? PaymentType.VERIPHONE_PT
      : this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE) ? PaymentType.STRIPE_PT : PaymentType.STRIPE_ONLINE

    let paymentReference: any
    if (paymentType == PaymentType.VERIPHONE_PT) {
      paymentReference = {
        ticket: this.debit.ticket ? this.debit.ticket : "",
        ticketNb: this.debit.ticketNb,
        serverTransactionNb: this.debit.serverTransactionNb,
        bankingServerNb: this.debit.bankingServerNb,
        transactionDateTime: this.debit.transactionDateTime
      }
    } else {
      paymentReference = {
        payment_intent_id: this.stripePaymentIntent?.id
      }
    }

    let patchedTip: PatchTipDto = {
      status: success ? PaymentStatus.DONE : PaymentStatus.CANCELLED,
      cerfaStatus: this.askForCerfa && success ? DocumentStatus.WAITING : DocumentStatus.NONE,
      ticketStatus: this.askForTicket && success ? DocumentStatus.WAITING : DocumentStatus.NONE,
      comment: "",
      finalized: success,
      paymentReference: paymentReference,
      paymentIntentId: this.appPaymentIntentId
    }

    return this.tipService.patch(this.currentTip.id, patchedTip)
  }

  showDocumentsModal(documentTypes: PaymentDocuments, tip: Tip): Observable<any> {
    const dialogRef = this.dialog.open(CustomerDetailsComponent, {
      width: '100%',
      disableClose: true,
      data: {
        paymentId: tip.paymentIds[0],
        askedDocuments: documentTypes,
        isKiosk: this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE),
        cardHolderName: this.stripeCardHolderName
      },
    })
    return dialogRef.afterClosed()
  }

  redirectOrResetAfterPayment(instantReset: boolean = true) {
    if (this.platformHelper.isPlatform(PlatformType.WEB_APP)) {
      let url = this.settings.fallbackUrl

      if (this.utilsHelper.isValidHttpUrl(url)) {
        window.location.href = url
      } else {
        this.reset()
      }
    } else {
      let timeout = instantReset ? 0 : this.DEFAULT_TIME_IN_MS_BEFORE_RESET_AFTER_PAYMENT

      setTimeout(() => {
        this.reset()
      }, timeout);
    }
  }

  startTimerBeforeResetAfterFailedPayment() {
    clearInterval(this.verifoneCancelPopupInterval)

    this.timeBeforeResetAfterFailedPaymentPopupInterval = setInterval(() => {
      this.cancelAfterFailedPayment()
    }, this.TIME_BEFORE_RESET_AFTER_FAILED_PAYMENT_IN_MS)
  }

  //#endregion

  //#region Medias API related methods

  retrieveLogoMedia() {
    let logoMediaId = this.theme.logoMediaId

    if (logoMediaId != "") {
      this.subscriptions.add(this.mediaService.getMedia(logoMediaId).subscribe(media => {
          this.logo = media
        })
      )
    }
  }

  retrieveScreensaverMedias() {
    const observables = this.theme.screensaverMediaIds.map(mediaId =>
      this.mediaService.getMedia(mediaId)
    )

    this.subscriptions.add(
      forkJoin(observables).subscribe(mediaArray => {
        this.screensaverMedias = mediaArray.filter(media => media.status === 'Success')
        if (this.currentAppStep == AppStep.SCREENSAVER) {
          this.startScreensaver()
        }
      })
    )
  }

  //#endregion

  //#region Verifone Payment related methods

  verifoneCheckStarted() {
    this.subscriptions.add(this.kioskApiService.started().subscribe(
      result => {
        this.verifoneStart()
      },
      error => {
        console.log(error)
        this.setErrorScreen(ErrorType.VERIPHONE_NOT_STARTED)
      }))
  }

  verifoneStart() {
    this.subscriptions.add(this.kioskApiService.start().subscribe(result => {
      this.verifoneDebit()
    }))
  }

  verifoneDebit() {
    let valueInCents = this.tipValue * 100
    let ticketNb = this.currentTip.invoiceNb.toString()

    this.verifoneCancelPopupInterval = setInterval(() => {
      this.verifoneCancelPopupVisible = true
    }, this.TIME_BEFORE_SHOWING_VERIFONE_CANCEL_HELPER_IN_MS)

    this.subscriptions.add(this.kioskApiService.debit(valueInCents, ticketNb).subscribe(result => {
      this.debit = result

      if (result.successful) {
        this.paymentStatus = PaymentStepStatus.SUCCESS
        this.registerTipAfterSuccess()
      } else {
        this.paymentStatus = PaymentStepStatus.FAILURE
        this.startTimerBeforeResetAfterFailedPayment()
      }
    }))
  }

  //#endregion

  //#region Stripe related methods

  checkIfDeviceHasStripeReader(
    deviceId: string,
    firstLaunch: boolean
  ) {
    this.subscriptions.add(
      this.stripeReaderService.getStripeReaderByDeviceId(deviceId).subscribe(
        reader => {
          this.localStorageHelper.setLocalStorageStripeTerminalSN(reader.stripeId)
          this.periodicPlatformExecution()
          if (firstLaunch) this.reset()
        },
        error => {
          this.setErrorScreen(ErrorType.STRIPE_PT_NOT_CONNECTED)
        }
      )
    )
  }

  async initTerminal() {
    if (this.settings.enabledPaymentTypes.includes(PaymentType.STRIPE_PT)) {
      if (!this.stripeTerminalConnected) {
        this.setErrorScreen(ErrorType.STRIPE_PT_NOT_CONNECTED)
        this.stripeTerminal = await loadStripeTerminal();

        if (this.stripeTerminal != null) {
          this.terminal = this.stripeTerminal.create({
            onFetchConnectionToken: async () => {
              const token = this.stripeApiService.getStripeAuthToken()
              return lastValueFrom(token);
            },
            onConnectionStatusChange: this.onConnectionStatusChange.bind(this),
            onUnexpectedReaderDisconnect: this.unexpectedDisconnect.bind(this),
          });

          this.connectReaderHandler();
        }
      }
    }
  }

  collectPayment(stripeClientSecret: string) {
    this.terminal.collectPaymentMethod(stripeClientSecret).then(result => {
      if ('error' in result) {
        console.log('error collect payment_intent : ', result.error)
        if (this.paymentStatus == PaymentStepStatus.WAITING) {
          this.paymentStatus = PaymentStepStatus.FAILURE
          this.startTimerBeforeResetAfterFailedPayment()
        }
      } else {
        console.log('collect payment_intent : ', result)
        clearTimeout(this.paymentTimeout)
        this.stripePaymentIntent = result.paymentIntent
        this.processPayment();
      }
    });
  }

  processPayment() {
    this.terminal.processPayment(this.stripePaymentIntent).then(result => {
      if ('error' in result) {
        console.log('error process payment_intent : ', result.error)
        this.paymentStatus = PaymentStepStatus.FAILURE
        this.startTimerBeforeResetAfterFailedPayment()
      } else if (result.paymentIntent) {
        this.stripePaymentIntent = result.paymentIntent
        console.log('process payment_intent : ', result.paymentIntent)
        this.capturePayment()
      }
    });
  }

  capturePayment() {
    this.subscriptions.add(
      this.stripeApiService.capturePayment(this.stripePaymentIntent.id).subscribe(result => {
        console.log('capture payment_intent : ', result)
        this.paymentStatus = PaymentStepStatus.SUCCESS
        this.registerTipAfterSuccess()
      })
    )
  }

  cancelPayment() {
    clearTimeout(this.paymentTimeout)
    this.terminal.cancelCollectPaymentMethod().then(_ => {
    });
  }

  onConnectionStatusChange(connectionStatusEvent: ConnectionStatusEvent) {
    console.log(connectionStatusEvent)
    this.stripeTerminalConnected = connectionStatusEvent.status == 'connected'
    this.errorMode = !this.stripeTerminalConnected

    if (this.errorMode) {
      clearTimeout(this.paymentTimeout)
      this.reset()
    }
  }

  unexpectedDisconnect(disconnectEvent: DisconnectEvent) {
    this.setErrorScreen(ErrorType.STRIPE_PT_NOT_CONNECTED)
    this.initTerminal()
  }

  connectReaderHandler() {
    let config = {simulated: this.simulatePayment};
    this.terminal.discoverReaders(config).then((discoverResult) => {
      if ('error' in discoverResult) {
        console.log('Failed to discover: ', discoverResult.error);
      } else {
        if (discoverResult.discoveredReaders.length === 0) {
          console.log('No available readers.');
        } else {
          // Just select the first reader here.
          console.log('reader list : ', discoverResult.discoveredReaders)

          let selectedReader
          if (this.simulatePayment) {
            selectedReader = discoverResult.discoveredReaders[0]
          } else {
            selectedReader = discoverResult.discoveredReaders
              .find(x => x.id == this.localStorageHelper.getLocalStorageStripeTerminalId());
          }

          this.terminal
            .connectReader(selectedReader)
            .then(function (connectResult) {
              console.log(connectResult)
              if ('error' in connectResult) {
                console.log('Failed to connect: ', connectResult.error);
              } else {
                console.log(
                  'Connected to reader: ',
                  connectResult.reader.label
                );
              }
            });
        }
      }
    });
  }

  //#endregion
}
