import {Component, ElementRef, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {PaymentService} from "../../services/payment/payment.service";
import {CustomerDetailsModalData} from "../../interfaces/customer-details-modal.data";
import {ThemeHelper} from "../../shared/helpers/themes/theme.helper";
import {PatchPayment} from "../../interfaces/patch-payment";
import {Platform, PlatformHelper, PlatformType} from "../../shared/helpers/platform/platform.helper";
import {DocumentStatus} from "../../interfaces/payment";

export enum PaymentDocuments {
  NONE,
  CERFA,
  TICKET,
  CERFA_AND_TICKET
}

export enum Steps {
  FILLING_INFORMATIONS,
  PROCESSING,
  SUCCESS
}

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit, OnDestroy {

  DEFAULT_TIME_BEFORE_AUTO_CANCEL_IN_SEC: number = 60

  PaymentDocuments: typeof PaymentDocuments = PaymentDocuments;
  Steps: typeof Steps = Steps;
  timerBeforeAutoCancel: NodeJS.Timeout
  timeBeforeAutoCancel: number = this.DEFAULT_TIME_BEFORE_AUTO_CANCEL_IN_SEC

  subscriptions: Subscription = new Subscription()

  customerDetailsForm: FormGroup = this.formBuilder.group({
    name: [this.data.cardHolderName],
    address: [''],
    postalCode: [''],
    city: [''],
    email: ['', [Validators.required]]
  })

  step: Steps = Steps.FILLING_INFORMATIONS
  datas: CustomerDetailsModalData

  platform: Platform
  showApplicationKeyboard: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private platformHelper: PlatformHelper,
    private elementRef: ElementRef,
    private themeHelper: ThemeHelper,
    public dialogRef: MatDialogRef<CustomerDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerDetailsModalData
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.platform = this.platformHelper.getPlatform()
    this.showApplicationKeyboard = this.platformHelper.platformShouldShowApplicationKeyboard()

    this.step = Steps.FILLING_INFORMATIONS
    this.datas = this.data

    this.themeHelper.loadTheme(this.elementRef.nativeElement)

    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE)) {
      this.timerBeforeAutoCancel = setInterval(function () {
        this.timeBeforeAutoCancel--
        if (this.timeBeforeAutoCancel == 0) {
          clearInterval(this.timerBeforeAutoCancel)
          this.cancel()
        }
      }.bind(this), 1000)
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timeBeforeAutoCancel)
  }

  @HostListener('click')
  @HostListener('keydown')
  onInteractionDetected() {
    this.timeBeforeAutoCancel = this.DEFAULT_TIME_BEFORE_AUTO_CANCEL_IN_SEC
  }

  validate() {
    this.step = Steps.PROCESSING
    let askCerfa = this.datas.askedDocuments == PaymentDocuments.CERFA || this.datas.askedDocuments == PaymentDocuments.CERFA_AND_TICKET
    let askTicket = this.datas.askedDocuments == PaymentDocuments.TICKET || this.datas.askedDocuments == PaymentDocuments.CERFA_AND_TICKET

    let patchPayment: PatchPayment = {
      updated: {
        customerName: this.customerDetailsForm.get('name').value,
        customerAddress: this.customerDetailsForm.get('address').value,
        customerEmail: this.customerDetailsForm.get('email').value,
        customerPostalCode: this.customerDetailsForm.get('postalCode').value,
        customerCity: this.customerDetailsForm.get('city').value,
        cerfaStatus: askCerfa ? DocumentStatus.WAITING : DocumentStatus.NONE,
        ticketStatus: askTicket ? DocumentStatus.WAITING : DocumentStatus.NONE
      }
    }

    this.subscriptions.add(this.paymentService.patch(this.datas.paymentId, patchPayment).subscribe(_payment => {
      this.step = Steps.SUCCESS
      setTimeout(() => {
        this.dialogRef.close()
      }, 5000);
    }))
  }

  cancel() {
    let askCerfa = this.datas.askedDocuments == PaymentDocuments.CERFA || this.datas.askedDocuments == PaymentDocuments.CERFA_AND_TICKET
    let askTicket = this.datas.askedDocuments == PaymentDocuments.TICKET || this.datas.askedDocuments == PaymentDocuments.CERFA_AND_TICKET

    let patchPayment: PatchPayment = {
      updated: {
        cerfaStatus: askCerfa ? DocumentStatus.CANCELLED : DocumentStatus.NONE,
        ticketStatus: askTicket ? DocumentStatus.CANCELLED : DocumentStatus.NONE
      }
    }

    this.subscriptions.add(this.paymentService.patch(this.datas.paymentId, patchPayment).subscribe(_payment => {
      this.dialogRef.close()
    }))
  }
}
