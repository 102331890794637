import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Settings} from "../../interfaces/settings";
import {Theme} from "../../interfaces/theme";
import {of, Subscription} from "rxjs";
import {Media} from "../../shared/interfaces/media";
import {catchError, map} from "rxjs/operators";
import {MediaService} from "../../services/medias/media.service";
import {Router} from "@angular/router";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-app-selection',
  templateUrl: './app-selection.component.html',
  styleUrls: ['./app-selection.component.scss']
})
export class AppSelectionComponent implements OnInit, OnDestroy {

  @Output() humetipsAppClicked: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() customerAppClicked: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() leaveCustomerAppClicked: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() settings: Settings
  @Input() theme: Theme

  subscriptions: Subscription = new Subscription()
  showWebsite: boolean = false

  logoMedia: Media = null
  customerAppLogoMedia: Media = null
  humetipsAppLogoMedia: Media = null

  customerUrl: SafeResourceUrl

  constructor(
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {
    // do nothing
  }

  ngOnInit(): void {
    let url = `${this.theme.appSelectionConfig.customerAppUrl}`
    this.customerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)

    this.retrieveMedias()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveMedias(): void {
    const {
      logoMediaId,
      appSelectionConfig: {
        customerAppLogoMediaId,
        humetipsAppLogoMediaId
      }
    } = this.theme

    const mediaIds = {
      [logoMediaId]: (media: Media) => this.logoMedia = media,
      [customerAppLogoMediaId]: (media: Media) => this.customerAppLogoMedia = media,
      [humetipsAppLogoMediaId]: (media: Media) => this.humetipsAppLogoMedia = media
    }

    this.subscriptions.add(
      ...Object.keys(mediaIds)
        .filter(mediaId => mediaId)
        .map(mediaId => this.mediaService.getMedia(mediaId).pipe(
          catchError(error => {
            console.log('Error retrieving media:', JSON.stringify(error))
            return of(null)
          }),
          map(media => mediaIds[mediaId](media))
        ).subscribe())
    )
  }

  goToCustomerApp() {
    this.showWebsite = true
    this.customerAppClicked.emit(true)
  }

  leaveCustomerApp() {
    this.leaveCustomerAppClicked.emit(true)
  }

  goToHumetips() {
    this.humetipsAppClicked.emit(true)
  }
}
