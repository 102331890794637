import {Component, OnInit} from '@angular/core';
import {ActivationEnd, NavigationEnd, NavigationError, Router} from "@angular/router";
import {Location} from '@angular/common';
import {LocalstorageHelper} from "./shared/helpers/localstorage/localstorage.helper";
import {PlatformHelper, PlatformType} from "./shared/helpers/platform/platform.helper";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'hti-kiosk-app';

  constructor(
    private router: Router,
    private location: Location,
    private platformHelper: PlatformHelper,
    private localStorageHelper: LocalstorageHelper
  ) {
    // do nothing
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof ActivationEnd) {
        if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_WEB_APP)) {
          const cid = event.snapshot.queryParams['cid'];
          if (cid) {
            this.localStorageHelper.setLocalStorageCid(cid)
          }
        }

        if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK)) {
          const deviceId = event.snapshot.queryParams['deviceId'];
          if (deviceId) {
            this.localStorageHelper.setLocalStorageDeviceId(deviceId)
          }
        }
      }

      if (event instanceof NavigationEnd) {
        const cid = this.localStorageHelper.getLocalStorageCid()
        const deviceId = this.localStorageHelper.getLocalStorageDeviceId()

        if (this.platformHelper.isPlatform(PlatformType.WEB_APP)) {
          if (cid && !event.url.includes('?cid')) {
            this.location.replaceState(event.url, `?cid=${cid}`)
            this.router.navigate(['/home']);
          } else {
            // Todo : Could load error page to inform that configuration is not ok
          }
        }

        if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK)) {
          if (cid && deviceId) {
            if (!event.url.includes('?cid') && !event.url.includes('?deviceId')) {
              this.location.replaceState(event.url, `?cid=${cid}&deviceId=${deviceId}`)
            }
            this.router.navigate(['/home']);
          } else {
            console.log("Configuration is not ok ")
            // Todo : Could load error page to inform that configuration is not ok
          }
        }

        if (this.platformHelper.isPlatform(PlatformType.KIOSK_DEVICE)) {
          if (!cid) {
            this.router.navigate(['/kiosk/pairing']);
          } else {
            this.router.navigate(['/home']);
          }
        }
      }

      if (event instanceof NavigationError) {
        console.log(event.error);
      }
    });
  }
}
