<div class="payment">
  <div class="payment_header">
    <div class="payment_header_title">
      <div [ngSwitch]="paymentStatus">
        <div *ngSwitchCase="PaymentStepStatus.NONE && !isUniqueRecipient">
          <span *ngIf="!isUniqueRecipient">
            {{ 'APP.PAYMENT.WEB.PAY_TIP_DESCRIPTION' | translate }} {{ tipValue | number: "1.2-2" }}€
          </span>
        </div>
        <div *ngSwitchCase="PaymentStepStatus.NONE">
          <span *ngIf="isUniqueRecipient">
            {{ 'APP.PAYMENT.WEB.PAY_DONATION_DESCRIPTION' | translate }} {{ tipValue | number: "1.2-2" }}€
          </span>
        </div>
        <div *ngSwitchCase="PaymentStepStatus.WAITING">
          {{ 'APP.PAYMENT.STEPS.PAYMENT_ONGOING' | translate }}
        </div>
        <div *ngSwitchCase="PaymentStepStatus.SUCCESS">
          {{ 'APP.PAYMENT.STEPS.PAYMENT_SUCCESS' | translate }}
        </div>
        <div *ngSwitchCase="PaymentStepStatus.FAILURE">
          {{ 'APP.PAYMENT.STEPS.PAYMENT_FAILED' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div [formGroup]="paymentForm" class="payment_body">

    <div class="payment_body_spinner" *ngIf="paymentStatus == PaymentStepStatus.WAITING">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div class="payment_body_result">
      <div class="payment_body_result_text payment_body_result_text--success"
           *ngIf="paymentStatus == PaymentStepStatus.SUCCESS">
        <div *ngIf="!isUniqueRecipient">
          {{ 'APP.PAYMENT.RESULTS.THANKS_LONG_TIP' | translate }}
        </div>
        <div *ngIf="isUniqueRecipient">
          {{ 'APP.PAYMENT.RESULTS.THANKS_LONG_DONATION' | translate }}
        </div>
      </div>
      <div class="payment_body_result_text payment_body_result_text--failure"
           *ngIf="paymentStatus == PaymentStepStatus.FAILURE">
        {{ 'APP.PAYMENT.RESULTS.ERROR' | translate }}
      </div>
      <button mat-raised-button class="payment_body_result_failure-btn"
              *ngIf="paymentStatus == PaymentStepStatus.FAILURE"
              (click)="pay()">
        {{ 'APP.PAYMENT.RESULTS.BUTTONS.TRY_AGAIN' | translate }}
      </button>
    </div>

    <div class="payment_body_form" [hidden]="paymentStatus != PaymentStepStatus.NONE">
      <div class="payment_body_form_payment-request-btn">
        <ngx-stripe-payment-request-button
          [paymentOptions]="paymentRequestOptions"
          [elementsOptions]="elementsOptions"
          (paymentMethod)="onPaymentMethod($event)">
        </ngx-stripe-payment-request-button>
      </div>
      <mat-form-field class="payment_body_form_input" appearance="outline">
        <mat-label>{{ 'APP.PAYMENT.WEB.NAME' | translate }}</mat-label>
        <input formControlName="name" matInput [placeholder]="'APP.PAYMENT.WEB.NAME_PLACEHOLDER' | translate" required>
      </mat-form-field>
      <div>

        <ngx-stripe-card
          [options]="cardOptions"
          [elementsOptions]="elementsOptions"
          (change)="onChange($event)"
        ></ngx-stripe-card>
      </div>
    </div>
  </div>

  <div class="payment_footer" *ngIf="paymentStatus == PaymentStepStatus.NONE">
    <button mat-raised-button class="payment_footer_pay-btn" (click)="pay()" [disabled]="!validForm">
      {{ 'APP.PAYMENT.WEB.PROCEED_BTN' | translate }}
    </button>
  </div>

</div>
