import {HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {filter, finalize, map, mergeMap} from 'rxjs/operators';
import {KeycloakService} from '../services/keycloak.service';
import {environment} from "../../../environments/environment";
import {LocalstorageHelper} from "../helpers/localstorage/localstorage.helper";
import {ApplicationHelper} from "../helpers/application/application.helper";
import {PlatformHelper, PlatformType} from "../helpers/platform/platform.helper";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private platformHelper: PlatformHelper,
    private localStorageHelper: LocalstorageHelper,
    private appHelper: ApplicationHelper
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    this.setupHumecloudInstance()

    const localCid = this.localStorageHelper.getLocalStorageCid()
    const htiUrl = this.appHelper.getApiUrlByType('HTI')
    const hclApi = this.appHelper.getApiUrlByType('SCREENLAB')
    const mediaUrl = this.appHelper.getApiUrlByType('MEDIAS')
    const keycloackUrl = this.appHelper.getApiUrlByType('KEYCLOACK')
    const emailUrl = this.appHelper.getApiUrlByType('EMAIL')
    const cmnApp = this.appHelper.getApiUrlByType('CMN_APP')


    if (request.url.startsWith(hclApi + '/public/devices')) {
      // do nothing
    } else if (request.url.startsWith(environment.kioskApi.baseUrl)) {
      let basicAuthValue = btoa(`${environment.kioskApi.user}:${environment.kioskApi.password}`);
      request = request.clone({
        setHeaders: {
          Authorization: `Basic ${basicAuthValue}`
        }
      })
      return next.handle(request).pipe(finalize(() => {
      }));
    } else if (request.url.startsWith(htiUrl) || request.url.startsWith(cmnApp) || request.url.startsWith(mediaUrl) || request.url.startsWith(emailUrl)) {
      return this.keycloakService.getAccessToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              },
              setParams: {
                realm: environment.htiApi.realm,
                cid: localCid
              }
            });
          }
          return next.handle(request).pipe(finalize(() => {
          }));
        }));
    } else if (request.url.startsWith(
      `${keycloackUrl}/realms/${environment.keycloak.realm}/protocol/openid-connect/token`)) {
      return next.handle(request).pipe(
        filter(httpEvent => httpEvent instanceof HttpResponse),
        map(httpEvent => httpEvent as HttpResponse<any>),
        mergeMap(response => {
          if (response.status == 400) {
            return this.keycloakService.getAccessToken(true)
          } else return of(response)
        }),
        finalize(() => {
        })
      );
    } else if (this.keycloakService.isAuthenticated()) {
      return this.keycloakService.getAccessToken().pipe(
        mergeMap((token) => {
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
          return next.handle(request).pipe(finalize(() => {
          }));
        }));
    }
    return next.handle(request).pipe(finalize(() => {
    }));
  }

  setupHumecloudInstance() {
    let instance = this.localStorageHelper.getLocalInstance()
    if (this.platformHelper.isPlatform(PlatformType.WEB_KIOSK_OR_WEB_APP)) {
      if (environment.enableDevMode) {
        const production = false // set this in development mode to change instance
        instance = production ? 'production' : 'development'
      } else {
        instance = window.location.origin.includes('dev') ? 'development' : 'production'
      }
    }

    if (instance) {
      this.localStorageHelper.setLocalInstance(instance);
    }
  }
}
