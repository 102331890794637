// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableDevMode: true,
  htiApi: {
    realm: 'scr',
    dev: {
      url: "https://kiosk-app.dev.humetips.com/#/home",
      api: "https://api.dev.humetips.humecloud.com"
    },
    prod: {
      url: "https://kiosk-app.humetips.com/#/home",
      api: "https://api.humetips.humecloud.com"
    }
  },
  mediaBaseUrlDev: "https://media.dev.humecloud.com",
  mediaBaseUrlProd: "https://media.humecloud.com",
  screenlabApiBaseUrlProd: "https://api.screenlab.humelab.com",
  screenlabApiBaseUrlDev: "https://api.dev.screenlab.humelab.com",
  cmnAppApiBaseUrlProd: 'https://api.common.humelab.com',
  cmnAppApiBaseUrlDev: 'https://api.dev.common.humelab.com',
  keycloak: {
    realm: 'scr',
    clientId: 'scr-portal',
    dev: {
      url: 'https://auth.dev.common.humelab.com/auth',
      username: 'hti-kiosk-app@humelab.com',
      password: 'h<]3=s=6jU=nN?0XC<]J'
    },
    prod: {
      url: 'https://auth.common.humelab.com/auth',
      username: 'hti-kiosk-app@humelab.com',
      password: 'Eoxv1OTh4&Z$'
    },
  },
  stripe: {
    publishable_key: 'pk_test_51Kyun3LGluIXzeLNbWLy8sqhvk6TOIq4rdUBkZRzGeAqtTDAKiEQiJRedmFVphclsDvcKHW3TwFKjcnOAhYWpVux00AV3PepjA',
    simulation_card: '4242424242424242'
  },
  kioskApi: {
    websocketBaseUrl: "ws://localhost:8080",
    baseUrl: "http://localhost:8080",
    user: "kiosk",
    password: "K1osk28?"
  },
  tweaks: {
    showAppType: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
