import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformHelper {

  DEV_PLATFORM_TYPE: Platform = Platform.WEB_KIOSK

  constructor() {
    // do nothing
  }


  getPlatform(): Platform {
    if (navigator.userAgent.indexOf("Tizen") != -1) {
      return Platform.TIZEN
    } else if (navigator.userAgent.indexOf("Electron") != -1) {
      return Platform.ELECTRON
    } else if (navigator.userAgent.indexOf("HtiAndroid") != -1 || window.location.origin.includes('ngrok')) {
      if (window.location.origin.includes('simulated')) {
        return Platform.SIMULATED_WEB_KIOSK
      }
      return Platform.ANDROID_WEB_KIOSK
    } else {
      if (window.location.origin.includes('localhost')) {
        return this.DEV_PLATFORM_TYPE
      } else if (window.location.origin.includes('kiosk') && window.location.origin.includes('simulated')) {
        return Platform.SIMULATED_WEB_KIOSK
      } else if (window.location.origin.includes('kiosk')) {
        return Platform.WEB_KIOSK
      } else {
        return Platform.WEB
      }
    }
  }

  platformShouldShowApplicationKeyboard(): boolean {
    let platform: Platform = this.getPlatform()

    switch (platform) {
      case Platform.WEB_KIOSK:
      case Platform.SIMULATED_WEB_KIOSK:
      case Platform.TIZEN:
      case Platform.ELECTRON:
        return true;
      case Platform.WEB:
      case Platform.ANDROID_WEB_KIOSK:
        return false;
      default:
        throw new Error('Unsupported platform');
    }
  }

  isPlatform(platformType: PlatformType): boolean {
    switch (platformType) {
      case PlatformType.WEB_KIOSK:
        return this.getPlatform() == Platform.WEB_KIOSK || this.getPlatform() == Platform.SIMULATED_WEB_KIOSK || this.getPlatform() == Platform.ANDROID_WEB_KIOSK
      case PlatformType.WEB_APP:
        return this.getPlatform() == Platform.WEB
      case PlatformType.KIOSK_DEVICE:
        return this.getPlatform() == Platform.TIZEN || this.getPlatform() == Platform.ELECTRON
      case PlatformType.WEB_KIOSK_OR_WEB_APP:
        return this.isPlatform(PlatformType.WEB_APP) || this.isPlatform(PlatformType.WEB_KIOSK)
      case PlatformType.WEB_KIOSK_OR_KIOSK_DEVICE:
        return this.isPlatform(PlatformType.WEB_KIOSK) || this.isPlatform(PlatformType.KIOSK_DEVICE)
    }
  }
}

export enum Platform {
  WEB_KIOSK,
  SIMULATED_WEB_KIOSK,
  ELECTRON,
  ANDROID_WEB_KIOSK,
  TIZEN,
  WEB
}

export enum PlatformType {
  WEB_KIOSK_OR_WEB_APP,
  WEB_KIOSK_OR_KIOSK_DEVICE,
  WEB_KIOSK,
  WEB_APP,
  KIOSK_DEVICE
}
