import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Theme } from "../../interfaces/theme";
import { map } from "rxjs/operators";
import { InvocationResult } from "../../shared/interfaces/invocation-result";
import { Observable } from "rxjs";
import { ApplicationHelper } from "../../shared/helpers/application/application.helper";

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(
    private httpClient: HttpClient,
    private appHelper: ApplicationHelper
  ) {
  }

  getThemeByThemeId(id: string): Observable<Theme> {
    const url = this.appHelper.getApiUrlByType('HTI')

    return this.httpClient.get(url + '/themes/' + id)
      .pipe(map((res: InvocationResult) => {
          return res.data;
        })
      );
  }
}
