<div class="app-selection">
  <ng-container *ngIf="!showWebsite">
    <div class="app-selection_logo">
      <img *ngIf="logoMedia" class="app-selection_logo_source" [src]="logoMedia.url"/>
      <img *ngIf="!logoMedia" class="app-selection_logo_source" src="assets/images/icons/icon-humetips.svg"/>
    </div>
    <div class="app-selection_tiles">
      <div class="app-selection_tiles_tile" (click)="goToCustomerApp()">
        <div class="app-selection_tiles_tile_content">
          <div class="app-selection_tiles_tile_content_logo">
            <img *ngIf="customerAppLogoMedia" class="app-selection_tiles_tile_content_logo_source"
                 [src]="customerAppLogoMedia.url"/>
          </div>
        </div>
        <div class="app-selection_tiles_tile_text"
             [innerHtml]="theme.appSelectionConfig.customerAppLabel | dynamicTranslation | classByChar: 'text-gradient-letter'">
        </div>
      </div>
      <div class="app-selection_tiles_tile" (click)="goToHumetips()">
        <div class="app-selection_tiles_tile_content">
          <div class="app-selection_tiles_tile_content_logo">
            <img *ngIf="humetipsAppLogoMedia" class="app-selection_tiles_tile_content_logo_source"
                 [src]="humetipsAppLogoMedia.url"/>
            <img *ngIf="!humetipsAppLogoMedia" class="app-selection_tiles_tile_content_logo_source"
                 src="assets/images/icons/tip.png"/>
          </div>
        </div>
        <div class="app-selection_tiles_tile_text" *ngIf="theme.appSelectionConfig.humetipsAppLabel != []"
             [innerHtml]="theme.appSelectionConfig.humetipsAppLabel | dynamicTranslation | classByChar: 'text-gradient-letter'">
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="showWebsite">
    <div class="website-container">
      <iframe id="iframe" class="website-container_iframe" [src]="customerUrl"></iframe>
      <div class="website-container_bottom-bar">
        <button (click)="leaveCustomerApp()">
          &laquo; {{ "APP.SELECTION.WEBSITE_CONTAINER.BACK_BUTTON" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
