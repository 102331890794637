import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from "./pages/home/home.component";
import { ApiAuthGuard } from "./shared/guards/apiAuth.guard";
import { EmulateDevicesGuard } from "./shared/guards/emulate-devices.guard";
import { PairingComponent } from "./pages/pairing/pairing.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "home",
    canActivate: [ApiAuthGuard, EmulateDevicesGuard],
    component: HomeComponent
  },
  {
    path: "kiosk/pairing",
    component: PairingComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    useHash: true,
    relativeLinkResolution: 'legacy'
  })],
  providers: [ApiAuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
