import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Language} from "../../interfaces/language";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent {
  @Output() clicked: EventEmitter<Language> = new EventEmitter<Language>()
  @Input() languages: Language[] = []

  constructor() {
    // do nothing
  }

  languageClicked(language: Language) {
    this.clicked.emit(language)
  }
}
