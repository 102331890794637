import {Pipe, PipeTransform} from '@angular/core';
import {Translation} from "../../../interfaces/translation";
import {LocalstorageHelper} from "../../helpers/localstorage/localstorage.helper";

@Pipe({
  name: 'dynamicTranslation'
})
export class DynamicTranslationPipe implements PipeTransform {

  constructor(
    private localStorageHelper: LocalstorageHelper,
  ) {
    // do nothing
  }

  transform(translations: Translation[], translationKey: string = null): string {
    if (!translations) return ""

    if (translationKey != null) {
      return this.translate(translations, translationKey);
    } else {
      return this.translateGlobal(translations);
    }
  }

  translateGlobal(translations: Translation[]): string {
    let languageCode = this.localStorageHelper.getLocalStorageLanguage()
      ? this.localStorageHelper.getLocalStorageLanguage().code : "fr"
    return this.translate(translations, languageCode);
  }

  translate(translations: any, translationKey: string): string {
    if (typeof translations === 'string') {
      return translations;
    } else {
      if (translations.length > 0) {
        let translation = translations.find((x) => x.code == translationKey);

        if (translation != null) {
          return translation.value;
        } else {
          return translations[0].value;
        }
      } else {
        return '';
      }
    }
  }
}
