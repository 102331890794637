import {Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class UtilsHelper {

  constructor() {
    // do nothing
  }

  getParamValueQueryString(url: string, paramName: string) {
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({fromString: url.split('?')[1]});
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }

  convertHexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      let r = parseInt(result[1], 16);
      let g = parseInt(result[2], 16);
      let b = parseInt(result[3], 16);
      return r + "," + g + "," + b;
    }
    return null;
  }

  isValidHttpUrl(urlToCheck: string) {
    let url;

    try {
      url = new URL(urlToCheck);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
